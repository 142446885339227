/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsCustomProduct,
    MelcoCoreModelsCustomProductFromJSON,
    MelcoCoreModelsCustomProductToJSON,
    MelcoCoreModelsDesignCollectionFullItem,
    MelcoCoreModelsDesignCollectionFullItemFromJSON,
    MelcoCoreModelsDesignCollectionFullItemToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItemToJSON,
    MelcoCoreModelsPersonalizationDownloads,
    MelcoCoreModelsPersonalizationDownloadsFromJSON,
    MelcoCoreModelsPersonalizationDownloadsToJSON,
    MelcoCoreModelsSession,
    MelcoCoreModelsSessionFromJSON,
    MelcoCoreModelsSessionToJSON,
    MelcoCoreModelsUpdateProductionStatus,
    MelcoCoreModelsUpdateProductionStatusFromJSON,
    MelcoCoreModelsUpdateProductionStatusToJSON,
} from '../models';

export interface ConfiguratorApiConfiguratorSessionDownloadRequest {
    sessionId: string | null;
    format?: string | null;
    printSheet?: boolean;
}

export interface ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest {
    personalizationId: string | null;
    format?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest {
    format?: string | null;
    melcoCoreModelsPersonalizationDownloads?: MelcoCoreModelsPersonalizationDownloads;
}

export interface ConfiguratorApiConfiguratorSessionsCompleteRequest {
    sessionId: string | null;
    melcoCoreModelsSession?: MelcoCoreModelsSession;
}

export interface ConfiguratorApiConfiguratorSessionsDesignCollectionRequest {
    id: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsDropRequest {
    sessionId: string | null;
    melcoCoreModelsSession?: MelcoCoreModelsSession;
}

export interface ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest {
    sessionId: string | null;
    width?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsGetPreviewRequest {
    sessionId: string | null;
    imageId: string | null;
    width?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsGetSessionRequest {
    sessionId: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsListRequest {
    searchText?: string | null;
    sessionStatus?: string | null;
    productionStatus?: string | null;
    startTimestamp?: Date | null;
    endTimestamp?: Date | null;
    offset?: number;
    limit?: number;
    sortBy?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest {
    personalizationId: string | null;
    width?: number;
    applyRotation?: boolean;
}

export interface ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest {
    sessionId: string | null;
    melcoCoreModelsUpdateProductionStatus?: MelcoCoreModelsUpdateProductionStatus;
}

export interface ConfiguratorApiConfiguratorSessionsProductsGetProductRequest {
    token: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest {
    searchText?: string | null;
    sessionStatus?: string | null;
    productionStatus?: string | null;
    startTimestamp?: Date | null;
    endTimestamp?: Date | null;
    offset?: number;
    limit?: number;
    sortBy?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsStartRequest {
    productToken?: string | null;
    sessionId?: string | null;
}

export interface ConfiguratorApiConfiguratorSessionsUpdateRequest {
    sessionId: string | null;
    melcoCoreModelsSession?: MelcoCoreModelsSession;
}

/**
 * 
 */
export class ConfiguratorApi extends runtime.BaseAPI {

    /**
     * download zip file with session production data
     * download session production files and metadata zip file
     */
    async configuratorSessionDownloadRaw(requestParameters: ConfiguratorApiConfiguratorSessionDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.printSheet !== undefined) {
            queryParameters['print_sheet'] = requestParameters.printSheet;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/download`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download zip file with session production data
     * download session production files and metadata zip file
     */
    async configuratorSessionDownload(requestParameters: ConfiguratorApiConfiguratorSessionDownloadRequest): Promise<Blob> {
        const response = await this.configuratorSessionDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * download production file
     * download production file for one personalization id
     */
    async configuratorSessionPersonalizationsDownloadRaw(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.personalizationId === null || requestParameters.personalizationId === undefined) {
            throw new runtime.RequiredError('personalizationId','Required parameter requestParameters.personalizationId was null or undefined when calling configuratorSessionPersonalizationsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations/{personalization_id}/download`.replace(`{${"personalization_id"}}`, encodeURIComponent(String(requestParameters.personalizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download production file
     * download production file for one personalization id
     */
    async configuratorSessionPersonalizationsDownload(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest): Promise<Blob> {
        const response = await this.configuratorSessionPersonalizationsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * download zip file with sessions data
     * download session product files and metadata zip file
     */
    async configuratorSessionPersonalizationsDownloadsRaw(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/personalizations/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsPersonalizationDownloadsToJSON(requestParameters.melcoCoreModelsPersonalizationDownloads),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download zip file with sessions data
     * download session product files and metadata zip file
     */
    async configuratorSessionPersonalizationsDownloads(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest): Promise<Blob> {
        const response = await this.configuratorSessionPersonalizationsDownloadsRaw(requestParameters);
        return await response.value();
    }

    /**
     * complete user personalization session
     */
    async configuratorSessionsCompleteRaw(requestParameters: ConfiguratorApiConfiguratorSessionsCompleteRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/complete`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSessionToJSON(requestParameters.melcoCoreModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSessionFromJSON(jsonValue));
    }

    /**
     * complete user personalization session
     */
    async configuratorSessionsComplete(requestParameters: ConfiguratorApiConfiguratorSessionsCompleteRequest): Promise<MelcoCoreModelsSession> {
        const response = await this.configuratorSessionsCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get published Design Collection with design element lists from configurator
     */
    async configuratorSessionsDesignCollectionRaw(requestParameters: ConfiguratorApiConfiguratorSessionsDesignCollectionRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesignCollectionFullItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling configuratorSessionsDesignCollection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/designs/collections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignCollectionFullItemFromJSON(jsonValue));
    }

    /**
     * Get published Design Collection with design element lists from configurator
     */
    async configuratorSessionsDesignCollection(requestParameters: ConfiguratorApiConfiguratorSessionsDesignCollectionRequest): Promise<MelcoCoreModelsDesignCollectionFullItem> {
        const response = await this.configuratorSessionsDesignCollectionRaw(requestParameters);
        return await response.value();
    }

    /**
     * drop user personalization session
     */
    async configuratorSessionsDropRaw(requestParameters: ConfiguratorApiConfiguratorSessionsDropRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsDrop.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/drop`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSessionToJSON(requestParameters.melcoCoreModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * drop user personalization session
     */
    async configuratorSessionsDrop(requestParameters: ConfiguratorApiConfiguratorSessionsDropRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.configuratorSessionsDropRaw(requestParameters);
        return await response.value();
    }

    /**
     * get default session preview image
     * get default session preview
     */
    async configuratorSessionsGetDefaultPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetDefaultPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/preview`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get default session preview image
     * get default session preview
     */
    async configuratorSessionsGetDefaultPreview(requestParameters: ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest): Promise<void> {
        await this.configuratorSessionsGetDefaultPreviewRaw(requestParameters);
    }

    /**
     * get session preview image
     * get session preview
     */
    async configuratorSessionsGetPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetPreview.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling configuratorSessionsGetPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/preview/{image_Id}`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"image_Id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get session preview image
     * get session preview
     */
    async configuratorSessionsGetPreview(requestParameters: ConfiguratorApiConfiguratorSessionsGetPreviewRequest): Promise<void> {
        await this.configuratorSessionsGetPreviewRaw(requestParameters);
    }

    /**
     * Get session metadata
     */
    async configuratorSessionsGetSessionRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetSessionRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSessionFromJSON(jsonValue));
    }

    /**
     * Get session metadata
     */
    async configuratorSessionsGetSession(requestParameters: ConfiguratorApiConfiguratorSessionsGetSessionRequest): Promise<MelcoCoreModelsSession> {
        const response = await this.configuratorSessionsGetSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * search sessions
     */
    async configuratorSessionsListRaw(requestParameters: ConfiguratorApiConfiguratorSessionsListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.sessionStatus !== undefined) {
            queryParameters['session_status'] = requestParameters.sessionStatus;
        }

        if (requestParameters.productionStatus !== undefined) {
            queryParameters['production_status'] = requestParameters.productionStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItemFromJSON(jsonValue));
    }

    /**
     * search sessions
     */
    async configuratorSessionsList(requestParameters: ConfiguratorApiConfiguratorSessionsListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsSessionListItem> {
        const response = await this.configuratorSessionsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get personalization preview
     * get personalization preview
     */
    async configuratorSessionsPersonalizationPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.personalizationId === null || requestParameters.personalizationId === undefined) {
            throw new runtime.RequiredError('personalizationId','Required parameter requestParameters.personalizationId was null or undefined when calling configuratorSessionsPersonalizationPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.applyRotation !== undefined) {
            queryParameters['apply_rotation'] = requestParameters.applyRotation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations/{personalization_id}/preview`.replace(`{${"personalization_id"}}`, encodeURIComponent(String(requestParameters.personalizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get personalization preview
     * get personalization preview
     */
    async configuratorSessionsPersonalizationPreview(requestParameters: ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest): Promise<void> {
        await this.configuratorSessionsPersonalizationPreviewRaw(requestParameters);
    }

    /**
     * update production status
     */
    async configuratorSessionsProductionStatusUpdateRaw(requestParameters: ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsProductionStatusUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/production/status`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUpdateProductionStatusToJSON(requestParameters.melcoCoreModelsUpdateProductionStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSessionFromJSON(jsonValue));
    }

    /**
     * update production status
     */
    async configuratorSessionsProductionStatusUpdate(requestParameters: ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest): Promise<MelcoCoreModelsSession> {
        const response = await this.configuratorSessionsProductionStatusUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get published product
     */
    async configuratorSessionsProductsGetProductRaw(requestParameters: ConfiguratorApiConfiguratorSessionsProductsGetProductRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling configuratorSessionsProductsGetProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/products/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * Get published product
     */
    async configuratorSessionsProductsGetProduct(requestParameters: ConfiguratorApiConfiguratorSessionsProductsGetProductRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.configuratorSessionsProductsGetProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * search personalizations
     */
    async configuratorSessionsSearchPersonalizationsRaw(requestParameters: ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.sessionStatus !== undefined) {
            queryParameters['session_status'] = requestParameters.sessionStatus;
        }

        if (requestParameters.productionStatus !== undefined) {
            queryParameters['production_status'] = requestParameters.productionStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItemFromJSON(jsonValue));
    }

    /**
     * search personalizations
     */
    async configuratorSessionsSearchPersonalizations(requestParameters: ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsSessionPersonalizationListItem> {
        const response = await this.configuratorSessionsSearchPersonalizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * start user personalization session or edit existing
     */
    async configuratorSessionsStartRaw(requestParameters: ConfiguratorApiConfiguratorSessionsStartRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSession>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productToken !== undefined) {
            queryParameters['product_token'] = requestParameters.productToken;
        }

        if (requestParameters.sessionId !== undefined) {
            queryParameters['session_Id'] = requestParameters.sessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/start`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSessionFromJSON(jsonValue));
    }

    /**
     * start user personalization session or edit existing
     */
    async configuratorSessionsStart(requestParameters: ConfiguratorApiConfiguratorSessionsStartRequest): Promise<MelcoCoreModelsSession> {
        const response = await this.configuratorSessionsStartRaw(requestParameters);
        return await response.value();
    }

    /**
     * update user personalization session
     */
    async configuratorSessionsUpdateRaw(requestParameters: ConfiguratorApiConfiguratorSessionsUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSessionToJSON(requestParameters.melcoCoreModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSessionFromJSON(jsonValue));
    }

    /**
     * update user personalization session
     */
    async configuratorSessionsUpdate(requestParameters: ConfiguratorApiConfiguratorSessionsUpdateRequest): Promise<MelcoCoreModelsSession> {
        const response = await this.configuratorSessionsUpdateRaw(requestParameters);
        return await response.value();
    }

}
