/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorJobMachineSetting
 */
export interface MelcoCoreModelsProductionCalculatorJobMachineSetting {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    apply_to_all_machines?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    speed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    color_change_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    bobbin_stitch_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    bobbin_change_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    stitches_next_thread_break?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    thread_break_fixing_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    hoop_change_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    trimming_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    cone_change_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobMachineSetting
     */
    maintenance_cost?: number;
}

export function MelcoCoreModelsProductionCalculatorJobMachineSettingFromJSON(json: any): MelcoCoreModelsProductionCalculatorJobMachineSetting {
    return MelcoCoreModelsProductionCalculatorJobMachineSettingFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorJobMachineSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorJobMachineSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apply_to_all_machines': !exists(json, 'apply_to_all_machines') ? undefined : json['apply_to_all_machines'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'color_change_time': !exists(json, 'color_change_time') ? undefined : json['color_change_time'],
        'bobbin_stitch_count': !exists(json, 'bobbin_stitch_count') ? undefined : json['bobbin_stitch_count'],
        'bobbin_change_time': !exists(json, 'bobbin_change_time') ? undefined : json['bobbin_change_time'],
        'stitches_next_thread_break': !exists(json, 'stitches_next_thread_break') ? undefined : json['stitches_next_thread_break'],
        'thread_break_fixing_time': !exists(json, 'thread_break_fixing_time') ? undefined : json['thread_break_fixing_time'],
        'hoop_change_time': !exists(json, 'hoop_change_time') ? undefined : json['hoop_change_time'],
        'trimming_time': !exists(json, 'trimming_time') ? undefined : json['trimming_time'],
        'cone_change_time': !exists(json, 'cone_change_time') ? undefined : json['cone_change_time'],
        'maintenance_cost': !exists(json, 'maintenance_cost') ? undefined : json['maintenance_cost'],
    };
}

export function MelcoCoreModelsProductionCalculatorJobMachineSettingToJSON(value?: MelcoCoreModelsProductionCalculatorJobMachineSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apply_to_all_machines': value.apply_to_all_machines,
        'speed': value.speed,
        'color_change_time': value.color_change_time,
        'bobbin_stitch_count': value.bobbin_stitch_count,
        'bobbin_change_time': value.bobbin_change_time,
        'stitches_next_thread_break': value.stitches_next_thread_break,
        'thread_break_fixing_time': value.thread_break_fixing_time,
        'hoop_change_time': value.hoop_change_time,
        'trimming_time': value.trimming_time,
        'cone_change_time': value.cone_change_time,
        'maintenance_cost': value.maintenance_cost,
    };
}


