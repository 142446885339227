import { useEffect, useState } from "react";
import { useAvailableViews } from "./useAvailableViews";

export type ActiveView = {
  index: number;
  name: string;
};

export const useActiveView = () => {
  const availableViews = useAvailableViews();

  const [activeView, setActiveView] = useState<ActiveView | undefined>();

  // set first view to be initially active
  useEffect(() => {
    if (!activeView && availableViews && availableViews.length > 0) {
      const { name } = availableViews[0];

      setActiveView({ index: 0, name });
    }
  }, [activeView, setActiveView, availableViews]);

  return [activeView, setActiveView] as const;
};
