/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorCalculationInfo
 */
export interface MelcoCoreModelsProductionCalculatorCalculationInfo {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    multihead?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    production_cost_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    optimal_heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    optimal_total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    optimal_production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    optimal_total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    optimal_production_cost_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    readonly potential_total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    readonly potential_production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    readonly potential_total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationInfo
     */
    readonly potential_production_cost_per_piece?: number;
}

export function MelcoCoreModelsProductionCalculatorCalculationInfoFromJSON(json: any): MelcoCoreModelsProductionCalculatorCalculationInfo {
    return MelcoCoreModelsProductionCalculatorCalculationInfoFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorCalculationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorCalculationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'total_production_time': !exists(json, 'total_production_time') ? undefined : json['total_production_time'],
        'production_time_per_piece': !exists(json, 'production_time_per_piece') ? undefined : json['production_time_per_piece'],
        'total_production_cost': !exists(json, 'total_production_cost') ? undefined : json['total_production_cost'],
        'production_cost_per_piece': !exists(json, 'production_cost_per_piece') ? undefined : json['production_cost_per_piece'],
        'optimal_heads': !exists(json, 'optimal_heads') ? undefined : json['optimal_heads'],
        'optimal_total_production_time': !exists(json, 'optimal_total_production_time') ? undefined : json['optimal_total_production_time'],
        'optimal_production_time_per_piece': !exists(json, 'optimal_production_time_per_piece') ? undefined : json['optimal_production_time_per_piece'],
        'optimal_total_production_cost': !exists(json, 'optimal_total_production_cost') ? undefined : json['optimal_total_production_cost'],
        'optimal_production_cost_per_piece': !exists(json, 'optimal_production_cost_per_piece') ? undefined : json['optimal_production_cost_per_piece'],
        'potential_total_production_time': !exists(json, 'potential_total_production_time') ? undefined : json['potential_total_production_time'],
        'potential_production_time_per_piece': !exists(json, 'potential_production_time_per_piece') ? undefined : json['potential_production_time_per_piece'],
        'potential_total_production_cost': !exists(json, 'potential_total_production_cost') ? undefined : json['potential_total_production_cost'],
        'potential_production_cost_per_piece': !exists(json, 'potential_production_cost_per_piece') ? undefined : json['potential_production_cost_per_piece'],
    };
}

export function MelcoCoreModelsProductionCalculatorCalculationInfoToJSON(value?: MelcoCoreModelsProductionCalculatorCalculationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'multihead': value.multihead,
        'heads': value.heads,
        'total_production_time': value.total_production_time,
        'production_time_per_piece': value.production_time_per_piece,
        'total_production_cost': value.total_production_cost,
        'production_cost_per_piece': value.production_cost_per_piece,
        'optimal_heads': value.optimal_heads,
        'optimal_total_production_time': value.optimal_total_production_time,
        'optimal_production_time_per_piece': value.optimal_production_time_per_piece,
        'optimal_total_production_cost': value.optimal_total_production_cost,
        'optimal_production_cost_per_piece': value.optimal_production_cost_per_piece,
    };
}


