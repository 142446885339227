/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsDesign,
    MelcoCoreModelsDesignFromJSON,
    MelcoCoreModelsDesignToJSON,
    MelcoCoreModelsEfsCreateFormat,
    MelcoCoreModelsEfsCreateFormatFromJSON,
    MelcoCoreModelsEfsCreateFormatToJSON,
    MelcoCoreModelsEfsCreateMapping,
    MelcoCoreModelsEfsCreateMappingFromJSON,
    MelcoCoreModelsEfsCreateMappingToJSON,
    MelcoCoreModelsEfsFormat,
    MelcoCoreModelsEfsFormatFromJSON,
    MelcoCoreModelsEfsFormatToJSON,
    MelcoCoreModelsEfsJob,
    MelcoCoreModelsEfsJobFromJSON,
    MelcoCoreModelsEfsJobToJSON,
    MelcoCoreModelsEfsMapping,
    MelcoCoreModelsEfsMappingFromJSON,
    MelcoCoreModelsEfsMappingToJSON,
    MelcoCoreModelsEfsQueueJob,
    MelcoCoreModelsEfsQueueJobFromJSON,
    MelcoCoreModelsEfsQueueJobToJSON,
    MelcoCoreModelsEfsRun,
    MelcoCoreModelsEfsRunFromJSON,
    MelcoCoreModelsEfsRunToJSON,
    MelcoCoreModelsEfsRunJobDetails,
    MelcoCoreModelsEfsRunJobDetailsFromJSON,
    MelcoCoreModelsEfsRunJobDetailsToJSON,
    MelcoCoreModelsEfsRunStatus,
    MelcoCoreModelsEfsRunStatusFromJSON,
    MelcoCoreModelsEfsRunStatusToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormat,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormatFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormatToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJob,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJobFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJobToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessor,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessorFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItemToJSON,
} from '../models';

export interface ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest {
    melcoCoreModelsEfsCreateFormat?: MelcoCoreModelsEfsCreateFormat;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest {
    id: string | null;
    melcoCoreModelsEfsCreateFormat?: MelcoCoreModelsEfsCreateFormat;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest {
    file?: Blob | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest {
    jobId: string | null;
    processor?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest {
    clientId?: string | null;
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest {
    jobId: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsRunRequest {
    melcoCoreModelsEfsJob?: MelcoCoreModelsEfsJob;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest {
    melcoCoreModelsEfsCreateMapping?: MelcoCoreModelsEfsCreateMapping;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest {
    id: string | null;
    melcoCoreModelsEfsCreateMapping?: MelcoCoreModelsEfsCreateMapping;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest {
    formatId?: string | null;
    global?: boolean;
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueGetRequest {
    processorId: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest {
    processorId: string | null;
    type: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest {
    processorId: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest {
    clientId: string | null;
    melcoCoreModelsEfsQueueJob?: MelcoCoreModelsEfsQueueJob;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest {
    id: string | null;
    jobId: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest {
    id: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest {
    id: string | null;
    jobId: string | null;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest {
    id: string | null;
    melcoCoreModelsEfsRunStatus?: MelcoCoreModelsEfsRunStatus;
}

/**
 * 
 */
export class ProductionFulfillmentApi extends runtime.BaseAPI {

    /**
     * get fulfillment supported default output format list
     */
    async productionFulfillmentDefaultOutputFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/defaults/output/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormatFromJSON(jsonValue));
    }

    /**
     * get fulfillment supported default output format list
     */
    async productionFulfillmentDefaultOutputFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsSettingEfsDefaultFormat> {
        const response = await this.productionFulfillmentDefaultOutputFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design meta data
     * get design meta data
     */
    async productionFulfillmentDesignsGetRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentDesignsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * get design meta data
     * get design meta data
     */
    async productionFulfillmentDesignsGet(requestParameters: ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.productionFulfillmentDesignsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get fulfillment exception list
     * get user fulfillment exception list
     */
    async productionFulfillmentExceptionsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/exceptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItemFromJSON(jsonValue));
    }

    /**
     * get fulfillment exception list
     * get user fulfillment exception list
     */
    async productionFulfillmentExceptionsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsExceptionListItem> {
        const response = await this.productionFulfillmentExceptionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new format
     * create new format
     */
    async productionFulfillmentFormatsCreateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/formats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsCreateFormatToJSON(requestParameters.melcoCoreModelsEfsCreateFormat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsFormatFromJSON(jsonValue));
    }

    /**
     * create new format
     * create new format
     */
    async productionFulfillmentFormatsCreate(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest): Promise<MelcoCoreModelsEfsFormat> {
        const response = await this.productionFulfillmentFormatsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete format
     */
    async productionFulfillmentFormatsDeleteRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete format
     */
    async productionFulfillmentFormatsDelete(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest): Promise<void> {
        await this.productionFulfillmentFormatsDeleteRaw(requestParameters);
    }

    /**
     * update existing format
     * update existing format
     */
    async productionFulfillmentFormatsEditRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsFormat>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsCreateFormatToJSON(requestParameters.melcoCoreModelsEfsCreateFormat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsFormatFromJSON(jsonValue));
    }

    /**
     * update existing format
     * update existing format
     */
    async productionFulfillmentFormatsEdit(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest): Promise<MelcoCoreModelsEfsFormat> {
        const response = await this.productionFulfillmentFormatsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * get format details
     * get format
     */
    async productionFulfillmentFormatsGetFormatRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsFormat>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsGetFormat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsFormatFromJSON(jsonValue));
    }

    /**
     * get format details
     * get format
     */
    async productionFulfillmentFormatsGetFormat(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest): Promise<MelcoCoreModelsEfsFormat> {
        const response = await this.productionFulfillmentFormatsGetFormatRaw(requestParameters);
        return await response.value();
    }

    /**
     * get fulfillment supported input format list
     */
    async productionFulfillmentFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/defaults/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItemFromJSON(jsonValue));
    }

    /**
     * get fulfillment supported input format list
     */
    async productionFulfillmentFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsGlobalFormatListItem> {
        const response = await this.productionFulfillmentFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user fulfillment format list
     * get user fulfillment format list
     */
    async productionFulfillmentFormatsGetUserListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItemFromJSON(jsonValue));
    }

    /**
     * get user fulfillment format list
     * get user fulfillment format list
     */
    async productionFulfillmentFormatsGetUserList(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsUserFormatListItem> {
        const response = await this.productionFulfillmentFormatsGetUserListRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new format
     * create new format (from file)
     */
    async productionFulfillmentFormatsUploadRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/production/fulfillment/formats/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsFormatFromJSON(jsonValue));
    }

    /**
     * create new format
     * create new format (from file)
     */
    async productionFulfillmentFormatsUpload(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest): Promise<MelcoCoreModelsEfsFormat> {
        const response = await this.productionFulfillmentFormatsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete job
     */
    async productionFulfillmentJobsDeleteQueuedJobRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentJobsDeleteQueuedJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.processor !== undefined) {
            queryParameters['processor'] = requestParameters.processor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete job
     */
    async productionFulfillmentJobsDeleteQueuedJob(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest): Promise<void> {
        await this.productionFulfillmentJobsDeleteQueuedJobRaw(requestParameters);
    }

    /**
     * get queued fulfillment job list
     * get queued fulfillment job list
     */
    async productionFulfillmentJobsGetJobListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJobFromJSON(jsonValue));
    }

    /**
     * get queued fulfillment job list
     * get queued fulfillment job list
     */
    async productionFulfillmentJobsGetJobList(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueJob> {
        const response = await this.productionFulfillmentJobsGetJobListRaw(requestParameters);
        return await response.value();
    }

    /**
     * run fulfillment job
     * get queued fulfillment job
     */
    async productionFulfillmentJobsGetQueuedJobRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentJobsGetQueuedJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * run fulfillment job
     * get queued fulfillment job
     */
    async productionFulfillmentJobsGetQueuedJob(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest): Promise<void> {
        await this.productionFulfillmentJobsGetQueuedJobRaw(requestParameters);
    }

    /**
     * run fulfillment job
     * run fulfillment job
     */
    async productionFulfillmentJobsRunRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsRunRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/jobs/run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsJobToJSON(requestParameters.melcoCoreModelsEfsJob),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * run fulfillment job
     * run fulfillment job
     */
    async productionFulfillmentJobsRun(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsRunRequest): Promise<void> {
        await this.productionFulfillmentJobsRunRaw(requestParameters);
    }

    /**
     * create new mapping
     * create new mapping
     */
    async productionFulfillmentMappingsCreateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsMapping>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/mappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsCreateMappingToJSON(requestParameters.melcoCoreModelsEfsCreateMapping),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsMappingFromJSON(jsonValue));
    }

    /**
     * create new mapping
     * create new mapping
     */
    async productionFulfillmentMappingsCreate(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest): Promise<MelcoCoreModelsEfsMapping> {
        const response = await this.productionFulfillmentMappingsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete mapping
     */
    async productionFulfillmentMappingsDeleteRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete mapping
     */
    async productionFulfillmentMappingsDelete(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest): Promise<void> {
        await this.productionFulfillmentMappingsDeleteRaw(requestParameters);
    }

    /**
     * duplicate mapping
     * duplicate mapping
     */
    async productionFulfillmentMappingsDuplicateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsMappingFromJSON(jsonValue));
    }

    /**
     * duplicate mapping
     * duplicate mapping
     */
    async productionFulfillmentMappingsDuplicate(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest): Promise<MelcoCoreModelsEfsMapping> {
        const response = await this.productionFulfillmentMappingsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing mapping
     * update existing mapping
     */
    async productionFulfillmentMappingsEditRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsCreateMappingToJSON(requestParameters.melcoCoreModelsEfsCreateMapping),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsMappingFromJSON(jsonValue));
    }

    /**
     * update existing mapping
     * update existing mapping
     */
    async productionFulfillmentMappingsEdit(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest): Promise<MelcoCoreModelsEfsMapping> {
        const response = await this.productionFulfillmentMappingsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user fulfillment mappings list
     * get user mappings list
     */
    async productionFulfillmentMappingsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.formatId !== undefined) {
            queryParameters['formatId'] = requestParameters.formatId;
        }

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItemFromJSON(jsonValue));
    }

    /**
     * get user fulfillment mappings list
     * get user mappings list
     */
    async productionFulfillmentMappingsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsMappingListItem> {
        const response = await this.productionFulfillmentMappingsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get mapping details
     * get mapping
     */
    async productionFulfillmentMappingsGetMappingsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsGetMappings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsMappingFromJSON(jsonValue));
    }

    /**
     * get mapping details
     * get mapping
     */
    async productionFulfillmentMappingsGetMappings(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest): Promise<MelcoCoreModelsEfsMapping> {
        const response = await this.productionFulfillmentMappingsGetMappingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get fulfillment supported output format list
     */
    async productionFulfillmentOutputFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/output/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormatFromJSON(jsonValue));
    }

    /**
     * get fulfillment supported output format list
     */
    async productionFulfillmentOutputFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsOutputFormat> {
        const response = await this.productionFulfillmentOutputFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get print job
     * get job from queue
     */
    async productionFulfillmentQueueGetRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsQueueJob>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/jobs/{processorId}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsQueueJobFromJSON(jsonValue));
    }

    /**
     * get print job
     * get job from queue
     */
    async productionFulfillmentQueueGet(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetRequest): Promise<MelcoCoreModelsEfsQueueJob> {
        const response = await this.productionFulfillmentQueueGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get queue processor list
     * get queue processor list
     */
    async productionFulfillmentQueueProcessorsListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessor>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessorFromJSON(jsonValue));
    }

    /**
     * get queue processor list
     * get queue processor list
     */
    async productionFulfillmentQueueProcessorsList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsQueueProcessor> {
        const response = await this.productionFulfillmentQueueProcessorsListRaw();
        return await response.value();
    }

    /**
     * send queue processor notifications
     * send queue processor notifications
     */
    async productionFulfillmentQueueProcessorsNotificationsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueProcessorsNotifications.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling productionFulfillmentQueueProcessorsNotifications.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors/{processorId}/notifications/{type}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send queue processor notifications
     * send queue processor notifications
     */
    async productionFulfillmentQueueProcessorsNotifications(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest): Promise<void> {
        await this.productionFulfillmentQueueProcessorsNotificationsRaw(requestParameters);
    }

    /**
     * update queue processor status
     * set queue processor status
     */
    async productionFulfillmentQueueProcessorsStatusRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueProcessorsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors/{processorId}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update queue processor status
     * set queue processor status
     */
    async productionFulfillmentQueueProcessorsStatus(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest): Promise<void> {
        await this.productionFulfillmentQueueProcessorsStatusRaw(requestParameters);
    }

    /**
     * send print job
     * send completed job status and data
     */
    async productionFulfillmentQueueUpdateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsQueueJob>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling productionFulfillmentQueueUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/queue/jobs/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsQueueJobToJSON(requestParameters.melcoCoreModelsEfsQueueJob),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsQueueJobFromJSON(jsonValue));
    }

    /**
     * send print job
     * send completed job status and data
     */
    async productionFulfillmentQueueUpdate(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest): Promise<MelcoCoreModelsEfsQueueJob> {
        const response = await this.productionFulfillmentQueueUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * download log file
     * download log file
     */
    async productionFulfillmentRunsDownloadLogsDataRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsDownloadLogsData.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentRunsDownloadLogsData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/job/{job_id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download log file
     * download log file
     */
    async productionFulfillmentRunsDownloadLogsData(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest): Promise<Blob> {
        const response = await this.productionFulfillmentRunsDownloadLogsDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * get run list
     * get run list
     */
    async productionFulfillmentRunsGetListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItemFromJSON(jsonValue));
    }

    /**
     * get run list
     * get run list
     */
    async productionFulfillmentRunsGetList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsEfsRunListItem> {
        const response = await this.productionFulfillmentRunsGetListRaw();
        return await response.value();
    }

    /**
     * get run definition
     * get run definition
     */
    async productionFulfillmentRunsGetRunRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsRun>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsGetRun.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsRunFromJSON(jsonValue));
    }

    /**
     * get run definition
     * get run definition
     */
    async productionFulfillmentRunsGetRun(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest): Promise<MelcoCoreModelsEfsRun> {
        const response = await this.productionFulfillmentRunsGetRunRaw(requestParameters);
        return await response.value();
    }

    /**
     * get run details
     * get run definition details
     */
    async productionFulfillmentRunsGetRunDetailsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsEfsRunJobDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsGetRunDetails.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentRunsGetRunDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/job/{job_id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsEfsRunJobDetailsFromJSON(jsonValue));
    }

    /**
     * get run details
     * get run definition details
     */
    async productionFulfillmentRunsGetRunDetails(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest): Promise<MelcoCoreModelsEfsRunJobDetails> {
        const response = await this.productionFulfillmentRunsGetRunDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * update run status
     * update run status
     */
    async productionFulfillmentRunsStatusRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/status`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsEfsRunStatusToJSON(requestParameters.melcoCoreModelsEfsRunStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * update run status
     * update run status
     */
    async productionFulfillmentRunsStatus(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.productionFulfillmentRunsStatusRaw(requestParameters);
        return await response.value();
    }

}
