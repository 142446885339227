/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMChangeLicenseLevel
 */
export interface MelcoCoreModelsDRMChangeLicenseLevel {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    subscription_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    referral_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    version_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    serial_number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMChangeLicenseLevel
     */
    step?: number;
}

export function MelcoCoreModelsDRMChangeLicenseLevelFromJSON(json: any): MelcoCoreModelsDRMChangeLicenseLevel {
    return MelcoCoreModelsDRMChangeLicenseLevelFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMChangeLicenseLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMChangeLicenseLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'subscription_code': !exists(json, 'subscription_code') ? undefined : json['subscription_code'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'version_id': !exists(json, 'version_id') ? undefined : json['version_id'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'step': !exists(json, 'step') ? undefined : json['step'],
    };
}

export function MelcoCoreModelsDRMChangeLicenseLevelToJSON(value?: MelcoCoreModelsDRMChangeLicenseLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_code': value.product_code,
        'subscription_code': value.subscription_code,
        'referral_code': value.referral_code,
        'version_id': value.version_id,
        'serial_number': value.serial_number,
        'step': value.step,
    };
}


