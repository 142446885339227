import { Modal } from "../modal/Modal";
import { useActiveView } from "../../hooks/useActiveView";
import { useProductColor } from "../../hooks/useProductColor";
import { useCancelSession } from "../../hooks/useCancelSession";
import { useFinishSession } from "../../hooks/useFinishSession";
import { ConfiguratorContent } from "./ConfiguratorContent";
import { ConfiguratorSidebar } from "./ConfiguratorSidebar";
import { DebugForm } from "../form/DebugForm";
import { OnCancelCallback, OnCompleteCallback } from "../..";
import { useErrorMessage } from "../../hooks/useErrorMessage";
import { ModalError } from "../modal/error/ModalError";
import { Button } from "../button/Button";
import { ConfirmActions } from "../../hooks/useConfirm";
import { useTranslation } from "react-i18next";

type DisplayConfiguratorProps = {
  apiKey: string;
  defaultColor?: string;
  onCancelCallback: OnCancelCallback;
  onCompleteCallback: OnCompleteCallback;
  onRestart: () => void;
  confirmActions: ConfirmActions;
  isProductColorChangeDisabled?: boolean;
  isDesignChangeDisabled?: boolean;
};

export const DisplayConfigurator: React.FC<DisplayConfiguratorProps> = ({
  apiKey,
  defaultColor,
  onCancelCallback,
  onCompleteCallback,
  onRestart,
  confirmActions,
  isProductColorChangeDisabled,
  isDesignChangeDisabled,
}) => {
  const { t } = useTranslation();

  const [activeView, setActiveView] = useActiveView();
  const [activeProductColor, setActiveProductColor] =
    useProductColor(defaultColor);

  const onCancel = useCancelSession(apiKey, onCancelCallback, {
    withConfirm: confirmActions,
  });
  const onCancelWithoutConfirm = useCancelSession(apiKey, onCancelCallback);
  const onFinish = useFinishSession(apiKey, onCompleteCallback);
  const errorMessage = useErrorMessage(onFinish.error);

  // TODO: Move this to global context?
  const pickerVisible = false;

  const errorComponent = errorMessage && (
    <ModalError
      text={errorMessage}
      buttons={[
        <Button key="modal-error-close" onClick={onCancelWithoutConfirm}>
          {t("actionButtons.close")}
        </Button>,
        <Button key="modal-error-retry" type="primary" onClick={onRestart}>
          {t("actionButtons.restart")}
        </Button>,
      ]}
    />
  );

  return (
    <>
      <Modal
        onClose={onCancel}
        sidebar={
          <ConfiguratorSidebar
            activeView={activeView}
            activeProductColor={activeProductColor}
            setActiveProductColor={setActiveProductColor}
            onCancel={onCancel}
            onFinish={onFinish}
            isProductColorChangeDisabled={isProductColorChangeDisabled}
            isDesignChangeDisabled={isDesignChangeDisabled}
            apiKey={apiKey}
          />
        }
        closeDisabled={pickerVisible}
        loading={onFinish.isLoading}
        errorComponent={errorComponent}
      >
        <ConfiguratorContent
          activeView={activeView}
          setActiveView={setActiveView}
          activeProductColor={activeProductColor}
          onCancel={onCancel}
          onFinish={onFinish}
        />
      </Modal>

      {false && <DebugForm />}
    </>
  );
};
