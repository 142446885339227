/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMUpgradeLicense
 */
export interface MelcoCoreModelsDRMUpgradeLicense {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    email_address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    version_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    subscription_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    referral_code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    step?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMUpgradeLicense
     */
    assign_license?: boolean;
}

export function MelcoCoreModelsDRMUpgradeLicenseFromJSON(json: any): MelcoCoreModelsDRMUpgradeLicense {
    return MelcoCoreModelsDRMUpgradeLicenseFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMUpgradeLicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMUpgradeLicense {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'version_id': !exists(json, 'version_id') ? undefined : json['version_id'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'subscription_code': !exists(json, 'subscription_code') ? undefined : json['subscription_code'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'step': !exists(json, 'step') ? undefined : json['step'],
        'assign_license': !exists(json, 'assign_license') ? undefined : json['assign_license'],
    };
}

export function MelcoCoreModelsDRMUpgradeLicenseToJSON(value?: MelcoCoreModelsDRMUpgradeLicense | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_address': value.email_address,
        'serial_number': value.serial_number,
        'version_id': value.version_id,
        'product_code': value.product_code,
        'subscription_code': value.subscription_code,
        'referral_code': value.referral_code,
        'step': value.step,
        'assign_license': value.assign_license,
    };
}


