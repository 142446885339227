import { parseDefaultDesignIDs } from "../../helper/designCollectionHelper";
import { useDesignCollectionsOfProduct } from "../../hooks/useDesignCollectionsOfProduct";
import { useProduct } from "../../hooks/useProduct";
import { DesignSelectorOptions } from "../../index";
import { DesignCollectionForView } from "./DesignCollectionForView";

type MainDesignSelectorProps = {
  token: string;
  apiKey: string;
  options: DesignSelectorOptions;
};

export const MainDesignSelector: React.FC<MainDesignSelectorProps> = ({
  token,
  apiKey,
  options,
}) => {
  const [product, isLoading] = useProduct(token, apiKey);

  const designCollectionsOfViews = useDesignCollectionsOfProduct(product);

  if (isLoading) {
    return null;
  }

  const { onDesignSelection, defaultDesignIDs } = options;

  const defaultDesigns = parseDefaultDesignIDs(defaultDesignIDs);

  return (
    <div className="mfc__design-collection-preview">
      {designCollectionsOfViews.map((designCollectionsOfView) => (
        <DesignCollectionForView
          key={designCollectionsOfView.viewName}
          designCollectionOfView={designCollectionsOfView}
          token={token}
          apiKey={apiKey}
          onDesignSelection={onDesignSelection}
          defaultDesignId={defaultDesigns[designCollectionsOfView.viewName]}
        />
      ))}
    </div>
  );
};
