import { MelcoCoreModelsSession } from "melco-shared-logic/dist/api/models/MelcoCoreModelsSession";
import { useCallback, useEffect, useState } from "react";
import { useConfigurator } from "./useConfigurator";

export const useStartSession = (
  apiKey: string,
  token: string,
  sessionId?: string
) => {
  const {
    isLoading,
    error,
    mutateAsync: startConfiguratorSession,
  } = useConfigurator(apiKey);

  const [session, setSession] = useState<MelcoCoreModelsSession | undefined>();

  const startSession = useCallback(async () => {
    try {
      const startedSession = await startConfiguratorSession({
        token,
        sessionId,
      });

      setSession(startedSession);
    } catch (e) {
      console.error(e);
    }
  }, [token]);

  useEffect(() => {
    startSession();
  }, [token]);

  return {
    session,
    isLoading,
    error,
    onRestart: startSession,
  };
};
