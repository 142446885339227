import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { Configuration } from "../../api";
import {
  DefaultAPIConfigOptions,
  useAuthenticatedAPIConfigAndCacheKey,
  useBaseAPIConfigAndCacheKey,
} from "./useAPIConfig";

export type UseAPIQueryConfig<
  TQueryFnData,
  TError,
  TData,
  TQueryKey extends QueryKey = QueryKey
> = {
  apiConfigOptions?: DefaultAPIConfigOptions;
  queryOptions?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >;
};

type UseApiQueryFunction<A = unknown, T = unknown> = (api: A) => T | Promise<T>;

export type UseApiQueryKey = unknown[];

export const useAPIQueryAndQueryKey = <
  A,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  APIClass: new (configuration: Configuration) => A,
  queryKey: UseApiQueryKey,
  queryFn: UseApiQueryFunction<A, TQueryFnData>,
  config?: UseAPIQueryConfig<TQueryFnData, TError, TData, UseApiQueryKey>
) => {
  const { apiConfigOptions, queryOptions } = config ?? {};

  const authenticatedApiConfig = useAuthenticatedAPIConfigAndCacheKey();
  const { config: apiConfig, cacheKey } =
    authenticatedApiConfig(apiConfigOptions);

  const api = new APIClass(apiConfig);
  const queryCacheKey: UseApiQueryKey = [...queryKey, cacheKey];

  return {
    query: useQuery(queryCacheKey, () => queryFn(api), queryOptions),
    queryKey: queryCacheKey,
  };
};

export const useAPIQuery = <
  A,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  APIClass: new (configuration: Configuration) => A,
  queryKey: UseApiQueryKey,
  queryFn: UseApiQueryFunction<A, TQueryFnData>,
  config?: UseAPIQueryConfig<TQueryFnData, TError, TData, UseApiQueryKey>
) => {
  const { query } = useAPIQueryAndQueryKey(APIClass, queryKey, queryFn, config);

  return query;
};

export const useUnauthenticatedAPIQueryAndQueryKey = <
  A,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  APIClass: new (configuration: Configuration) => A,
  queryKey: UseApiQueryKey,
  queryFn: UseApiQueryFunction<A, TQueryFnData>,
  config?: UseAPIQueryConfig<TQueryFnData, TError, TData, UseApiQueryKey>
) => {
  const { apiConfigOptions, queryOptions } = config ?? {};

  const unauthenticatedApiConfig = useBaseAPIConfigAndCacheKey();
  const { config: apiConfig, cacheKey } =
    unauthenticatedApiConfig(apiConfigOptions);

  const api = new APIClass(apiConfig);
  const queryCacheKey: UseApiQueryKey = [...queryKey, cacheKey];

  return {
    query: useQuery(queryCacheKey, () => queryFn(api), queryOptions),
    queryKey: queryCacheKey,
  };
};

export const useUnauthenticatedAPIQuery = <
  A,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  APIClass: new (configuration: Configuration) => A,
  queryKey: UseApiQueryKey,
  queryFn: UseApiQueryFunction<A, TQueryFnData>,
  config?: UseAPIQueryConfig<TQueryFnData, TError, TData, UseApiQueryKey>
) => {
  const { query } = useUnauthenticatedAPIQueryAndQueryKey(
    APIClass,
    queryKey,
    queryFn,
    config
  );

  return query;
};
