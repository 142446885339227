/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsProductionCalculatorMachineSetting,
    MelcoCoreModelsProductionCalculatorMachineSettingFromJSON,
    MelcoCoreModelsProductionCalculatorMachineSettingFromJSONTyped,
    MelcoCoreModelsProductionCalculatorMachineSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorMachineUpdateRequest
 */
export interface MelcoCoreModelsProductionCalculatorMachineUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    multihead?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    investment?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    amortization_months?: number;
    /**
     * 
     * @type {MelcoCoreModelsProductionCalculatorMachineSetting}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    settings?: MelcoCoreModelsProductionCalculatorMachineSetting;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorMachineUpdateRequest
     */
    recalculate_all_jobs?: boolean;
}

export function MelcoCoreModelsProductionCalculatorMachineUpdateRequestFromJSON(json: any): MelcoCoreModelsProductionCalculatorMachineUpdateRequest {
    return MelcoCoreModelsProductionCalculatorMachineUpdateRequestFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorMachineUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorMachineUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'investment': !exists(json, 'investment') ? undefined : json['investment'],
        'amortization_months': !exists(json, 'amortization_months') ? undefined : json['amortization_months'],
        'settings': !exists(json, 'settings') ? undefined : MelcoCoreModelsProductionCalculatorMachineSettingFromJSON(json['settings']),
        'recalculate_all_jobs': !exists(json, 'recalculate_all_jobs') ? undefined : json['recalculate_all_jobs'],
    };
}

export function MelcoCoreModelsProductionCalculatorMachineUpdateRequestToJSON(value?: MelcoCoreModelsProductionCalculatorMachineUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'multihead': value.multihead,
        'heads': value.heads,
        'investment': value.investment,
        'amortization_months': value.amortization_months,
        'settings': MelcoCoreModelsProductionCalculatorMachineSettingToJSON(value.settings),
        'recalculate_all_jobs': value.recalculate_all_jobs,
    };
}


