/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorJobRequest
 */
export interface MelcoCoreModelsProductionCalculatorJobRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    orders?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    pieces?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    color_changes?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    cone_changes?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    garment_handling?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    hooping?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobRequest
     */
    other_work?: number;
}

export function MelcoCoreModelsProductionCalculatorJobRequestFromJSON(json: any): MelcoCoreModelsProductionCalculatorJobRequest {
    return MelcoCoreModelsProductionCalculatorJobRequestFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorJobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorJobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orders': !exists(json, 'orders') ? undefined : json['orders'],
        'pieces': !exists(json, 'pieces') ? undefined : json['pieces'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'color_changes': !exists(json, 'color_changes') ? undefined : json['color_changes'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'cone_changes': !exists(json, 'cone_changes') ? undefined : json['cone_changes'],
        'garment_handling': !exists(json, 'garment_handling') ? undefined : json['garment_handling'],
        'hooping': !exists(json, 'hooping') ? undefined : json['hooping'],
        'other_work': !exists(json, 'other_work') ? undefined : json['other_work'],
    };
}

export function MelcoCoreModelsProductionCalculatorJobRequestToJSON(value?: MelcoCoreModelsProductionCalculatorJobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'orders': value.orders,
        'pieces': value.pieces,
        'stitches': value.stitches,
        'colors': value.colors,
        'color_changes': value.color_changes,
        'trims': value.trims,
        'cone_changes': value.cone_changes,
        'garment_handling': value.garment_handling,
        'hooping': value.hooping,
        'other_work': value.other_work,
    };
}


