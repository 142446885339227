/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsHoopsHoopCategory,
    MelcoCoreModelsHoopsHoopCategoryFromJSON,
    MelcoCoreModelsHoopsHoopCategoryFromJSONTyped,
    MelcoCoreModelsHoopsHoopCategoryToJSON,
    MelcoCoreModelsHoopsHoopMachine,
    MelcoCoreModelsHoopsHoopMachineFromJSON,
    MelcoCoreModelsHoopsHoopMachineFromJSONTyped,
    MelcoCoreModelsHoopsHoopMachineToJSON,
    MelcoCoreModelsHoopsHoopProduct,
    MelcoCoreModelsHoopsHoopProductFromJSON,
    MelcoCoreModelsHoopsHoopProductFromJSONTyped,
    MelcoCoreModelsHoopsHoopProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsHoop
 */
export interface MelcoCoreModelsHoop {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    english_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    metric_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsHoop
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsHoop
     */
    width_points?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsHoop
     */
    height_points?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    presser_foot?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsHoop
     */
    enable_templates?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    backward_compatibility_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    supplier_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    supplier_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    supplier_product_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    supplier_product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    asset_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsHoop
     */
    discontinued?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsHoop
     */
    publish_state?: number;
    /**
     * 
     * @type {Array<MelcoCoreModelsHoopsHoopCategory>}
     * @memberof MelcoCoreModelsHoop
     */
    category_list?: Array<MelcoCoreModelsHoopsHoopCategory> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsHoopsHoopProduct>}
     * @memberof MelcoCoreModelsHoop
     */
    product_list?: Array<MelcoCoreModelsHoopsHoopProduct> | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsHoopsHoopMachine>}
     * @memberof MelcoCoreModelsHoop
     */
    machine_list?: Array<MelcoCoreModelsHoopsHoopMachine> | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsHoop
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsHoop
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsHoop
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    asset?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsHoop
     */
    signature?: string | null;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsHoop
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
}

export function MelcoCoreModelsHoopFromJSON(json: any): MelcoCoreModelsHoop {
    return MelcoCoreModelsHoopFromJSONTyped(json, false);
}

export function MelcoCoreModelsHoopFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsHoop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'english_name': !exists(json, 'english_name') ? undefined : json['english_name'],
        'metric_name': !exists(json, 'metric_name') ? undefined : json['metric_name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'width_points': !exists(json, 'width_points') ? undefined : json['width_points'],
        'height_points': !exists(json, 'height_points') ? undefined : json['height_points'],
        'presser_foot': !exists(json, 'presser_foot') ? undefined : json['presser_foot'],
        'enable_templates': !exists(json, 'enable_templates') ? undefined : json['enable_templates'],
        'backward_compatibility_id': !exists(json, 'backward_compatibility_id') ? undefined : json['backward_compatibility_id'],
        'supplier_id': !exists(json, 'supplier_id') ? undefined : json['supplier_id'],
        'supplier_name': !exists(json, 'supplier_name') ? undefined : json['supplier_name'],
        'supplier_product_id': !exists(json, 'supplier_product_id') ? undefined : json['supplier_product_id'],
        'supplier_product_name': !exists(json, 'supplier_product_name') ? undefined : json['supplier_product_name'],
        'asset_type': !exists(json, 'asset_type') ? undefined : json['asset_type'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'discontinued': !exists(json, 'discontinued') ? undefined : json['discontinued'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'category_list': !exists(json, 'category_list') ? undefined : (json['category_list'] === null ? null : (json['category_list'] as Array<any>).map(MelcoCoreModelsHoopsHoopCategoryFromJSON)),
        'product_list': !exists(json, 'product_list') ? undefined : (json['product_list'] === null ? null : (json['product_list'] as Array<any>).map(MelcoCoreModelsHoopsHoopProductFromJSON)),
        'machine_list': !exists(json, 'machine_list') ? undefined : (json['machine_list'] === null ? null : (json['machine_list'] as Array<any>).map(MelcoCoreModelsHoopsHoopMachineFromJSON)),
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'asset': !exists(json, 'asset') ? undefined : json['asset'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
    };
}

export function MelcoCoreModelsHoopToJSON(value?: MelcoCoreModelsHoop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'english_name': value.english_name,
        'metric_name': value.metric_name,
        'version': value.version,
        'width_points': value.width_points,
        'height_points': value.height_points,
        'presser_foot': value.presser_foot,
        'enable_templates': value.enable_templates,
        'backward_compatibility_id': value.backward_compatibility_id,
        'supplier_id': value.supplier_id,
        'supplier_name': value.supplier_name,
        'supplier_product_id': value.supplier_product_id,
        'supplier_product_name': value.supplier_product_name,
        'asset_type': value.asset_type,
        'type': value.type,
        'discontinued': value.discontinued,
        'publish_state': value.publish_state,
        'category_list': value.category_list === undefined ? undefined : (value.category_list === null ? null : (value.category_list as Array<any>).map(MelcoCoreModelsHoopsHoopCategoryToJSON)),
        'product_list': value.product_list === undefined ? undefined : (value.product_list === null ? null : (value.product_list as Array<any>).map(MelcoCoreModelsHoopsHoopProductToJSON)),
        'machine_list': value.machine_list === undefined ? undefined : (value.machine_list === null ? null : (value.machine_list as Array<any>).map(MelcoCoreModelsHoopsHoopMachineToJSON)),
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'asset': value.asset,
        'signature': value.signature,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
    };
}


