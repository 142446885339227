/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculation,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculationFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculationToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotification,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotificationFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotificationToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponse,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponseFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponseToJSON,
    MelcoCoreModelsProductionCalculatorCalculationDetail,
    MelcoCoreModelsProductionCalculatorCalculationDetailFromJSON,
    MelcoCoreModelsProductionCalculatorCalculationDetailToJSON,
    MelcoCoreModelsProductionCalculatorChangeActiveMachine,
    MelcoCoreModelsProductionCalculatorChangeActiveMachineFromJSON,
    MelcoCoreModelsProductionCalculatorChangeActiveMachineToJSON,
    MelcoCoreModelsProductionCalculatorJob,
    MelcoCoreModelsProductionCalculatorJobFromJSON,
    MelcoCoreModelsProductionCalculatorJobToJSON,
    MelcoCoreModelsProductionCalculatorJobDefault,
    MelcoCoreModelsProductionCalculatorJobDefaultFromJSON,
    MelcoCoreModelsProductionCalculatorJobDefaultToJSON,
    MelcoCoreModelsProductionCalculatorJobMachineSetting,
    MelcoCoreModelsProductionCalculatorJobMachineSettingFromJSON,
    MelcoCoreModelsProductionCalculatorJobMachineSettingToJSON,
    MelcoCoreModelsProductionCalculatorJobRequest,
    MelcoCoreModelsProductionCalculatorJobRequestFromJSON,
    MelcoCoreModelsProductionCalculatorJobRequestToJSON,
    MelcoCoreModelsProductionCalculatorJobResponse,
    MelcoCoreModelsProductionCalculatorJobResponseFromJSON,
    MelcoCoreModelsProductionCalculatorJobResponseToJSON,
    MelcoCoreModelsProductionCalculatorMachineRequest,
    MelcoCoreModelsProductionCalculatorMachineRequestFromJSON,
    MelcoCoreModelsProductionCalculatorMachineRequestToJSON,
    MelcoCoreModelsProductionCalculatorMachineResponse,
    MelcoCoreModelsProductionCalculatorMachineResponseFromJSON,
    MelcoCoreModelsProductionCalculatorMachineResponseToJSON,
    MelcoCoreModelsProductionCalculatorMachineSetting,
    MelcoCoreModelsProductionCalculatorMachineSettingFromJSON,
    MelcoCoreModelsProductionCalculatorMachineSettingToJSON,
    MelcoCoreModelsProductionCalculatorMachineUpdateRequest,
    MelcoCoreModelsProductionCalculatorMachineUpdateRequestFromJSON,
    MelcoCoreModelsProductionCalculatorMachineUpdateRequestToJSON,
    MelcoCoreModelsProductionCalculatorSettingRequest,
    MelcoCoreModelsProductionCalculatorSettingRequestFromJSON,
    MelcoCoreModelsProductionCalculatorSettingRequestToJSON,
    MelcoCoreModelsProductionCalculatorSettingResponse,
    MelcoCoreModelsProductionCalculatorSettingResponseFromJSON,
    MelcoCoreModelsProductionCalculatorSettingResponseToJSON,
} from '../models';

export interface ProductionCalculatorApiJobsApplyGlobalSettingsRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsChangeActiveRequest {
    jobId: string | null;
    melcoCoreModelsProductionCalculatorChangeActiveMachine?: MelcoCoreModelsProductionCalculatorChangeActiveMachine;
}

export interface ProductionCalculatorApiJobsCreateRequest {
    melcoCoreModelsProductionCalculatorJobRequest?: MelcoCoreModelsProductionCalculatorJobRequest;
}

export interface ProductionCalculatorApiJobsDeleteRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsDuplicateRequest {
    name?: string | null;
    jobId?: string | null;
}

export interface ProductionCalculatorApiJobsEditRequest {
    jobId: string | null;
    melcoCoreModelsProductionCalculatorJobRequest?: MelcoCoreModelsProductionCalculatorJobRequest;
}

export interface ProductionCalculatorApiJobsGetActiveMachineRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetCalculationDetailsRequest {
    jobId: string | null;
    calculationId: string | null;
}

export interface ProductionCalculatorApiJobsGetCalculationListRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetJobRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetJobMachineSettingsRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetListRequest {
    searchText?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    offset?: number;
}

export interface ProductionCalculatorApiJobsJobExportRequest {
    jobId: string | null;
    culture?: string | null;
}

export interface ProductionCalculatorApiJobsUpdateDefaultsRequest {
    melcoCoreModelsProductionCalculatorJobDefault?: MelcoCoreModelsProductionCalculatorJobDefault;
}

export interface ProductionCalculatorApiJobsUpdateMachineSettingsRequest {
    jobId: string | null;
    melcoCoreModelsProductionCalculatorJobMachineSetting?: MelcoCoreModelsProductionCalculatorJobMachineSetting;
}

export interface ProductionCalculatorApiMachinesCreateRequest {
    melcoCoreModelsProductionCalculatorMachineRequest?: MelcoCoreModelsProductionCalculatorMachineRequest;
}

export interface ProductionCalculatorApiMachinesDeleteRequest {
    machineId: string | null;
}

export interface ProductionCalculatorApiMachinesDuplicateRequest {
    name?: string | null;
    machineId?: string | null;
}

export interface ProductionCalculatorApiMachinesGetRequest {
    machineId: string | null;
}

export interface ProductionCalculatorApiMachinesUpdateRequest {
    machineId: string | null;
    melcoCoreModelsProductionCalculatorMachineUpdateRequest?: MelcoCoreModelsProductionCalculatorMachineUpdateRequest;
}

export interface ProductionCalculatorApiSettingsUpdateRequest {
    melcoCoreModelsProductionCalculatorSettingRequest?: MelcoCoreModelsProductionCalculatorSettingRequest;
}

/**
 * 
 */
export class ProductionCalculatorApi extends runtime.BaseAPI {

    /**
     * apply default settings to job
     * apply default settings to job
     */
    async jobsApplyGlobalSettingsRaw(requestParameters: ProductionCalculatorApiJobsApplyGlobalSettingsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsApplyGlobalSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/applyglobalsettings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * apply default settings to job
     * apply default settings to job
     */
    async jobsApplyGlobalSettings(requestParameters: ProductionCalculatorApiJobsApplyGlobalSettingsRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.jobsApplyGlobalSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * change used machine
     * change used machine
     */
    async jobsChangeActiveRaw(requestParameters: ProductionCalculatorApiJobsChangeActiveRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJob>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsChangeActive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/changeactive`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorChangeActiveMachineToJSON(requestParameters.melcoCoreModelsProductionCalculatorChangeActiveMachine),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobFromJSON(jsonValue));
    }

    /**
     * change used machine
     * change used machine
     */
    async jobsChangeActive(requestParameters: ProductionCalculatorApiJobsChangeActiveRequest): Promise<MelcoCoreModelsProductionCalculatorJob> {
        const response = await this.jobsChangeActiveRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new job
     * create new job
     */
    async jobsCreateRaw(requestParameters: ProductionCalculatorApiJobsCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJobResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorJobRequestToJSON(requestParameters.melcoCoreModelsProductionCalculatorJobRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobResponseFromJSON(jsonValue));
    }

    /**
     * create new job
     * create new job
     */
    async jobsCreate(requestParameters: ProductionCalculatorApiJobsCreateRequest): Promise<MelcoCoreModelsProductionCalculatorJobResponse> {
        const response = await this.jobsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete job
     */
    async jobsDeleteRaw(requestParameters: ProductionCalculatorApiJobsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete job
     */
    async jobsDelete(requestParameters: ProductionCalculatorApiJobsDeleteRequest): Promise<void> {
        await this.jobsDeleteRaw(requestParameters);
    }

    /**
     * check for duplicate job existence
     * job duplicate check
     */
    async jobsDuplicateRaw(requestParameters: ProductionCalculatorApiJobsDuplicateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.jobId !== undefined) {
            queryParameters['jobId'] = requestParameters.jobId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/duplicate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * check for duplicate job existence
     * job duplicate check
     */
    async jobsDuplicate(requestParameters: ProductionCalculatorApiJobsDuplicateRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.jobsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing job
     * update existing job
     */
    async jobsEditRaw(requestParameters: ProductionCalculatorApiJobsEditRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJobResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorJobRequestToJSON(requestParameters.melcoCoreModelsProductionCalculatorJobRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobResponseFromJSON(jsonValue));
    }

    /**
     * update existing job
     * update existing job
     */
    async jobsEdit(requestParameters: ProductionCalculatorApiJobsEditRequest): Promise<MelcoCoreModelsProductionCalculatorJobResponse> {
        const response = await this.jobsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * get active machine
     * get active machine
     */
    async jobsGetActiveMachineRaw(requestParameters: ProductionCalculatorApiJobsGetActiveMachineRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetActiveMachine.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * get active machine
     * get active machine
     */
    async jobsGetActiveMachine(requestParameters: ProductionCalculatorApiJobsGetActiveMachineRequest): Promise<MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.jobsGetActiveMachineRaw(requestParameters);
        return await response.value();
    }

    /**
     * get calculation details
     * get job calculation details
     */
    async jobsGetCalculationDetailsRaw(requestParameters: ProductionCalculatorApiJobsGetCalculationDetailsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorCalculationDetail>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetCalculationDetails.');
        }

        if (requestParameters.calculationId === null || requestParameters.calculationId === undefined) {
            throw new runtime.RequiredError('calculationId','Required parameter requestParameters.calculationId was null or undefined when calling jobsGetCalculationDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/calculations/{calculationId}/details`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))).replace(`{${"calculationId"}}`, encodeURIComponent(String(requestParameters.calculationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorCalculationDetailFromJSON(jsonValue));
    }

    /**
     * get calculation details
     * get job calculation details
     */
    async jobsGetCalculationDetails(requestParameters: ProductionCalculatorApiJobsGetCalculationDetailsRequest): Promise<MelcoCoreModelsProductionCalculatorCalculationDetail> {
        const response = await this.jobsGetCalculationDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job calculation list
     * get job calculation list
     */
    async jobsGetCalculationListRaw(requestParameters: ProductionCalculatorApiJobsGetCalculationListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculation>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetCalculationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/calculations`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculationFromJSON(jsonValue));
    }

    /**
     * get job calculation list
     * get job calculation list
     */
    async jobsGetCalculationList(requestParameters: ProductionCalculatorApiJobsGetCalculationListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorCalculation> {
        const response = await this.jobsGetCalculationListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job details
     * get job
     */
    async jobsGetJobRaw(requestParameters: ProductionCalculatorApiJobsGetJobRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJobResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobResponseFromJSON(jsonValue));
    }

    /**
     * get job details
     * get job
     */
    async jobsGetJob(requestParameters: ProductionCalculatorApiJobsGetJobRequest): Promise<MelcoCoreModelsProductionCalculatorJobResponse> {
        const response = await this.jobsGetJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job defaults
     * get job defaults
     */
    async jobsGetJobDefaultsRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJobDefault>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/defaults`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobDefaultFromJSON(jsonValue));
    }

    /**
     * get job defaults
     * get job defaults
     */
    async jobsGetJobDefaults(): Promise<MelcoCoreModelsProductionCalculatorJobDefault> {
        const response = await this.jobsGetJobDefaultsRaw();
        return await response.value();
    }

    /**
     * get used/active machine
     * get used/active machine settings
     */
    async jobsGetJobMachineSettingsRaw(requestParameters: ProductionCalculatorApiJobsGetJobMachineSettingsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineSetting>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetJobMachineSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/settings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineSettingFromJSON(jsonValue));
    }

    /**
     * get used/active machine
     * get used/active machine settings
     */
    async jobsGetJobMachineSettings(requestParameters: ProductionCalculatorApiJobsGetJobMachineSettingsRequest): Promise<MelcoCoreModelsProductionCalculatorMachineSetting> {
        const response = await this.jobsGetJobMachineSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of jobs
     * get jobs list
     */
    async jobsGetListRaw(requestParameters: ProductionCalculatorApiJobsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItemFromJSON(jsonValue));
    }

    /**
     * get list of jobs
     * get jobs list
     */
    async jobsGetList(requestParameters: ProductionCalculatorApiJobsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorJobListItem> {
        const response = await this.jobsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * export csv/excel
     * export job
     */
    async jobsJobExportRaw(requestParameters: ProductionCalculatorApiJobsJobExportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsJobExport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.culture !== undefined) {
            queryParameters['culture'] = requestParameters.culture;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/export`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * export csv/excel
     * export job
     */
    async jobsJobExport(requestParameters: ProductionCalculatorApiJobsJobExportRequest): Promise<void> {
        await this.jobsJobExportRaw(requestParameters);
    }

    /**
     * update job defaults
     * update job defaults
     */
    async jobsUpdateDefaultsRaw(requestParameters: ProductionCalculatorApiJobsUpdateDefaultsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorJobDefault>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/defaults`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorJobDefaultToJSON(requestParameters.melcoCoreModelsProductionCalculatorJobDefault),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorJobDefaultFromJSON(jsonValue));
    }

    /**
     * update job defaults
     * update job defaults
     */
    async jobsUpdateDefaults(requestParameters: ProductionCalculatorApiJobsUpdateDefaultsRequest): Promise<MelcoCoreModelsProductionCalculatorJobDefault> {
        const response = await this.jobsUpdateDefaultsRaw(requestParameters);
        return await response.value();
    }

    /**
     * update machine(s) settings for this job
     * update machine(s) settings for this job
     */
    async jobsUpdateMachineSettingsRaw(requestParameters: ProductionCalculatorApiJobsUpdateMachineSettingsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineSetting>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsUpdateMachineSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/settings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorJobMachineSettingToJSON(requestParameters.melcoCoreModelsProductionCalculatorJobMachineSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineSettingFromJSON(jsonValue));
    }

    /**
     * update machine(s) settings for this job
     * update machine(s) settings for this job
     */
    async jobsUpdateMachineSettings(requestParameters: ProductionCalculatorApiJobsUpdateMachineSettingsRequest): Promise<MelcoCoreModelsProductionCalculatorMachineSetting> {
        const response = await this.jobsUpdateMachineSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new machine
     * create new machine
     */
    async machinesCreateRaw(requestParameters: ProductionCalculatorApiMachinesCreateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorMachineRequestToJSON(requestParameters.melcoCoreModelsProductionCalculatorMachineRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * create new machine
     * create new machine
     */
    async machinesCreate(requestParameters: ProductionCalculatorApiMachinesCreateRequest): Promise<MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.machinesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete machine
     * delete machine
     */
    async machinesDeleteRaw(requestParameters: ProductionCalculatorApiMachinesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete machine
     * delete machine
     */
    async machinesDelete(requestParameters: ProductionCalculatorApiMachinesDeleteRequest): Promise<void> {
        await this.machinesDeleteRaw(requestParameters);
    }

    /**
     * check for duplicate machine existence
     * machine duplicate check
     */
    async machinesDuplicateRaw(requestParameters: ProductionCalculatorApiMachinesDuplicateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/duplicate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * check for duplicate machine existence
     * machine duplicate check
     */
    async machinesDuplicate(requestParameters: ProductionCalculatorApiMachinesDuplicateRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.machinesDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get machine object
     * get machine by machineId
     */
    async machinesGetRaw(requestParameters: ProductionCalculatorApiMachinesGetRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineResponse>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * get machine object
     * get machine by machineId
     */
    async machinesGet(requestParameters: ProductionCalculatorApiMachinesGetRequest): Promise<MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.machinesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get machines list
     * get machines list
     */
    async machinesGetListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * get machines list
     * get machines list
     */
    async machinesGetList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.machinesGetListRaw();
        return await response.value();
    }

    /**
     * get list of default machine prices to auto suggest user
     * get default machine settings list
     */
    async machinesGetMachineListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/defaults/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * get list of default machine prices to auto suggest user
     * get default machine settings list
     */
    async machinesGetMachineList(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.machinesGetMachineListRaw();
        return await response.value();
    }

    /**
     * get notification list
     * get notification list
     */
    async machinesGetNotificationsRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotification>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotificationFromJSON(jsonValue));
    }

    /**
     * get notification list
     * get notification list
     */
    async machinesGetNotifications(): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsProductionCalculatorMachineNotification> {
        const response = await this.machinesGetNotificationsRaw();
        return await response.value();
    }

    /**
     * update machine
     * update machine
     */
    async machinesUpdateRaw(requestParameters: ProductionCalculatorApiMachinesUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorMachineResponse>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorMachineUpdateRequestToJSON(requestParameters.melcoCoreModelsProductionCalculatorMachineUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(jsonValue));
    }

    /**
     * update machine
     * update machine
     */
    async machinesUpdate(requestParameters: ProductionCalculatorApiMachinesUpdateRequest): Promise<MelcoCoreModelsProductionCalculatorMachineResponse> {
        const response = await this.machinesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get production calculator account settings
     * get account settings
     */
    async productionCalculatorSettingsGetRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorSettingResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorSettingResponseFromJSON(jsonValue));
    }

    /**
     * get production calculator account settings
     * get account settings
     */
    async productionCalculatorSettingsGet(): Promise<MelcoCoreModelsProductionCalculatorSettingResponse> {
        const response = await this.productionCalculatorSettingsGetRaw();
        return await response.value();
    }

    /**
     * update user account production calculator settings
     * update user settings
     */
    async settingsUpdateRaw(requestParameters: ProductionCalculatorApiSettingsUpdateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsProductionCalculatorSettingResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsProductionCalculatorSettingRequestToJSON(requestParameters.melcoCoreModelsProductionCalculatorSettingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsProductionCalculatorSettingResponseFromJSON(jsonValue));
    }

    /**
     * update user account production calculator settings
     * update user settings
     */
    async settingsUpdate(requestParameters: ProductionCalculatorApiSettingsUpdateRequest): Promise<MelcoCoreModelsProductionCalculatorSettingResponse> {
        const response = await this.settingsUpdateRaw(requestParameters);
        return await response.value();
    }

}
