/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsDesign,
    MelcoCoreModelsDesignFromJSON,
    MelcoCoreModelsDesignToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItemToJSON,
} from '../models';

export interface APIServiceApiUserDesignsDesignEditorAutoDigitizeDownloadRequest {
    newWidth?: number;
    newHeight?: number;
    imageFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorAutoDigitizeMetadataRequest {
    newWidth?: number;
    newHeight?: number;
    imageFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorAutoDigitizePreviewRequest {
    newWidth?: number;
    newHeight?: number;
    imageFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorColorizePreviewRequest {
    designFile?: Blob | null;
    colorFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorDeleteRequest {
    id: string | null;
    force?: boolean;
}

export interface APIServiceApiUserDesignsDesignEditorDownloadRequest {
    designFile?: Blob | null;
    colorFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorDownloadByIdRequest {
    id: string | null;
}

export interface APIServiceApiUserDesignsDesignEditorGetDefRequest {
    id: string | null;
}

export interface APIServiceApiUserDesignsDesignEditorMetadataRequest {
    designFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorPreviewByIdRequest {
    id: string | null;
}

export interface APIServiceApiUserDesignsDesignEditorSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
    service?: string | null;
}

export interface APIServiceApiUserDesignsDesignEditorUpdateFileRequest {
    id: string | null;
    designFile?: Blob | null;
}

export interface APIServiceApiUserDesignsDesignEditorUploadRequest {
    designFile?: Blob | null;
}

/**
 * 
 */
export class APIServiceApi extends runtime.BaseAPI {

    /**
     * Download by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizeDownloadRaw(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizeDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.newWidth !== undefined) {
            queryParameters['new_width'] = requestParameters.newWidth;
        }

        if (requestParameters.newHeight !== undefined) {
            queryParameters['new_height'] = requestParameters.newHeight;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.imageFile !== undefined) {
            formParams.append('image_file', requestParameters.imageFile as any);
        }

        const response = await this.request({
            path: `/design-editor/digitize/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizeDownload(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizeDownloadRequest): Promise<Blob> {
        const response = await this.userDesignsDesignEditorAutoDigitizeDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get design info by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizeMetadataRaw(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizeMetadataRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.newWidth !== undefined) {
            queryParameters['new_width'] = requestParameters.newWidth;
        }

        if (requestParameters.newHeight !== undefined) {
            queryParameters['new_height'] = requestParameters.newHeight;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.imageFile !== undefined) {
            formParams.append('image_file', requestParameters.imageFile as any);
        }

        const response = await this.request({
            path: `/design-editor/digitize/metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * Get design info by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizeMetadata(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizeMetadataRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.userDesignsDesignEditorAutoDigitizeMetadataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get preview by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizePreviewRaw(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizePreviewRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.newWidth !== undefined) {
            queryParameters['new_width'] = requestParameters.newWidth;
        }

        if (requestParameters.newHeight !== undefined) {
            queryParameters['new_height'] = requestParameters.newHeight;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.imageFile !== undefined) {
            formParams.append('image_file', requestParameters.imageFile as any);
        }

        const response = await this.request({
            path: `/design-editor/digitize/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get preview by digitizing the image file.
     */
    async userDesignsDesignEditorAutoDigitizePreview(requestParameters: APIServiceApiUserDesignsDesignEditorAutoDigitizePreviewRequest): Promise<Blob> {
        const response = await this.userDesignsDesignEditorAutoDigitizePreviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Image by changing colors of the design
     */
    async userDesignsDesignEditorColorizePreviewRaw(requestParameters: APIServiceApiUserDesignsDesignEditorColorizePreviewRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designFile !== undefined) {
            formParams.append('design_file', requestParameters.designFile as any);
        }

        if (requestParameters.colorFile !== undefined) {
            formParams.append('color_file', requestParameters.colorFile as any);
        }

        const response = await this.request({
            path: `/design-editor/colorize/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get Image by changing colors of the design
     */
    async userDesignsDesignEditorColorizePreview(requestParameters: APIServiceApiUserDesignsDesignEditorColorizePreviewRequest): Promise<Blob> {
        const response = await this.userDesignsDesignEditorColorizePreviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete design
     */
    async userDesignsDesignEditorDeleteRaw(requestParameters: APIServiceApiUserDesignsDesignEditorDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDesignEditorDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/design-editor/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete design
     */
    async userDesignsDesignEditorDelete(requestParameters: APIServiceApiUserDesignsDesignEditorDeleteRequest): Promise<void> {
        await this.userDesignsDesignEditorDeleteRaw(requestParameters);
    }

    /**
     * Get Production file by changing design
     */
    async userDesignsDesignEditorDownloadRaw(requestParameters: APIServiceApiUserDesignsDesignEditorDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designFile !== undefined) {
            formParams.append('design_file', requestParameters.designFile as any);
        }

        if (requestParameters.colorFile !== undefined) {
            formParams.append('color_file', requestParameters.colorFile as any);
        }

        const response = await this.request({
            path: `/design-editor/colorize/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get Production file by changing design
     */
    async userDesignsDesignEditorDownload(requestParameters: APIServiceApiUserDesignsDesignEditorDownloadRequest): Promise<Blob> {
        const response = await this.userDesignsDesignEditorDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * download design with all the query string modifications i.e. rotation, color changes and text changes
     */
    async userDesignsDesignEditorDownloadByIdRaw(requestParameters: APIServiceApiUserDesignsDesignEditorDownloadByIdRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDesignEditorDownloadById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/design-editor/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download design with all the query string modifications i.e. rotation, color changes and text changes
     */
    async userDesignsDesignEditorDownloadById(requestParameters: APIServiceApiUserDesignsDesignEditorDownloadByIdRequest): Promise<Blob> {
        const response = await this.userDesignsDesignEditorDownloadByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user design
     * get design metadata
     */
    async userDesignsDesignEditorGetDefRaw(requestParameters: APIServiceApiUserDesignsDesignEditorGetDefRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDesignEditorGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/design-editor/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * get user design
     * get design metadata
     */
    async userDesignsDesignEditorGetDef(requestParameters: APIServiceApiUserDesignsDesignEditorGetDefRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.userDesignsDesignEditorGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get metadata for design file
     */
    async userDesignsDesignEditorMetadataRaw(requestParameters: APIServiceApiUserDesignsDesignEditorMetadataRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designFile !== undefined) {
            formParams.append('design_file', requestParameters.designFile as any);
        }

        const response = await this.request({
            path: `/design-editor/colorize/metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * Get metadata for design file
     */
    async userDesignsDesignEditorMetadata(requestParameters: APIServiceApiUserDesignsDesignEditorMetadataRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.userDesignsDesignEditorMetadataRaw(requestParameters);
        return await response.value();
    }

    /**
     * preview design with all the query string modifications i.e. rotation, color changes and text changes
     */
    async userDesignsDesignEditorPreviewByIdRaw(requestParameters: APIServiceApiUserDesignsDesignEditorPreviewByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDesignEditorPreviewById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/design-editor/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * preview design with all the query string modifications i.e. rotation, color changes and text changes
     */
    async userDesignsDesignEditorPreviewById(requestParameters: APIServiceApiUserDesignsDesignEditorPreviewByIdRequest): Promise<void> {
        await this.userDesignsDesignEditorPreviewByIdRaw(requestParameters);
    }

    /**
     * search user designs
     */
    async userDesignsDesignEditorSearchRaw(requestParameters: APIServiceApiUserDesignsDesignEditorSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/design-editor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItemFromJSON(jsonValue));
    }

    /**
     * search user designs
     */
    async userDesignsDesignEditorSearch(requestParameters: APIServiceApiUserDesignsDesignEditorSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsDesignListItem> {
        const response = await this.userDesignsDesignEditorSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsDesignEditorUpdateFileRaw(requestParameters: APIServiceApiUserDesignsDesignEditorUpdateFileRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDesignEditorUpdateFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designFile !== undefined) {
            formParams.append('design_file', requestParameters.designFile as any);
        }

        const response = await this.request({
            path: `/design-editor/{Id}/update`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsDesignEditorUpdateFile(requestParameters: APIServiceApiUserDesignsDesignEditorUpdateFileRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.userDesignsDesignEditorUpdateFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload design
     * upload design
     */
    async userDesignsDesignEditorUploadRaw(requestParameters: APIServiceApiUserDesignsDesignEditorUploadRequest): Promise<runtime.ApiResponse<MelcoCoreModelsDesign>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.designFile !== undefined) {
            formParams.append('design_file', requestParameters.designFile as any);
        }

        const response = await this.request({
            path: `/design-editor/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsDesignFromJSON(jsonValue));
    }

    /**
     * upload design
     * upload design
     */
    async userDesignsDesignEditorUpload(requestParameters: APIServiceApiUserDesignsDesignEditorUploadRequest): Promise<MelcoCoreModelsDesign> {
        const response = await this.userDesignsDesignEditorUploadRaw(requestParameters);
        return await response.value();
    }

}
