/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsDRMUserLicense
 */
export interface MelcoCoreModelsDRMUserLicense {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    subscription_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    referral_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    version_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    email_address?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    is_validate_only?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsDRMUserLicense
     */
    assign_license?: boolean;
}

export function MelcoCoreModelsDRMUserLicenseFromJSON(json: any): MelcoCoreModelsDRMUserLicense {
    return MelcoCoreModelsDRMUserLicenseFromJSONTyped(json, false);
}

export function MelcoCoreModelsDRMUserLicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsDRMUserLicense {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'subscription_code': !exists(json, 'subscription_code') ? undefined : json['subscription_code'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'version_id': !exists(json, 'version_id') ? undefined : json['version_id'],
        'email_address': !exists(json, 'email_address') ? undefined : json['email_address'],
        'is_validate_only': !exists(json, 'is_validate_only') ? undefined : json['is_validate_only'],
        'assign_license': !exists(json, 'assign_license') ? undefined : json['assign_license'],
    };
}

export function MelcoCoreModelsDRMUserLicenseToJSON(value?: MelcoCoreModelsDRMUserLicense | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_code': value.product_code,
        'subscription_code': value.subscription_code,
        'referral_code': value.referral_code,
        'version_id': value.version_id,
        'email_address': value.email_address,
        'is_validate_only': value.is_validate_only,
        'assign_license': value.assign_license,
    };
}


