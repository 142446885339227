import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";
import { ViewSelector } from "./ViewSelector";
import { Preview } from "./Preview";
import { Button } from "../button/Button";
import { ActiveView } from "../../hooks/useActiveView";
import { ActiveColor } from "../../hooks/useProductColor";
import { useDesign } from "../../hooks/useDesign";
import "./ConfiguratorContent.css";
import { useFormikContext } from "formik";

type ConfiguratorContentProps = {
  activeView: ActiveView | undefined;
  setActiveView: (activeView: ActiveView | undefined) => void;
  activeProductColor: ActiveColor | undefined;
  onCancel: () => void;
  onFinish: UseMutationResult;
};

export const ConfiguratorContent: React.FC<ConfiguratorContentProps> = ({
  activeView,
  setActiveView,
  activeProductColor,
  onCancel,
  onFinish,
}) => {
  const { t } = useTranslation();

  const { isValid } = useFormikContext();

  const [design, designRendererMatrix] = useDesign(
    activeView,
    activeProductColor
  );

  const { mutate: finish, isLoading } = onFinish;

  const isUIDisabled = false;

  // ensure it get's re-initialized when either the view, product color (and hence blank picture) or design changes
  const rendererKey = `preview-${activeView?.index}-${activeProductColor?.argb}-${design?.rfm_url}`;

  return (
    <div className="mfc__configurator">
      <ViewSelector
        activeView={activeView}
        setActiveView={setActiveView}
        activeProductColor={activeProductColor}
        isUIDisabled={isUIDisabled}
      />

      <Preview
        key={rendererKey}
        design={design}
        designRendererMatrix={designRendererMatrix}
        activeView={activeView}
        activeProductColor={activeProductColor}
      />

      <div className="mfc__configurator-action-buttons">
        <div className="mfc__configurator-action-buttons-inner">
          <Button onClick={onCancel} disabled={isUIDisabled}>
            {t("actionButtons.cancel")}
          </Button>
          <Button
            type="primary"
            disabled={isUIDisabled || isLoading || !isValid}
            onClick={finish}
          >
            {t("actionButtons.finish")}
          </Button>
        </div>
      </div>
    </div>
  );
};
