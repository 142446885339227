/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsProductionCalculatorCalculation,
    MelcoCoreModelsProductionCalculatorCalculationFromJSON,
    MelcoCoreModelsProductionCalculatorCalculationFromJSONTyped,
    MelcoCoreModelsProductionCalculatorCalculationToJSON,
    MelcoCoreModelsProductionCalculatorJobResponse,
    MelcoCoreModelsProductionCalculatorJobResponseFromJSON,
    MelcoCoreModelsProductionCalculatorJobResponseFromJSONTyped,
    MelcoCoreModelsProductionCalculatorJobResponseToJSON,
    MelcoCoreModelsProductionCalculatorMachineResponse,
    MelcoCoreModelsProductionCalculatorMachineResponseFromJSON,
    MelcoCoreModelsProductionCalculatorMachineResponseFromJSONTyped,
    MelcoCoreModelsProductionCalculatorMachineResponseToJSON,
    MelcoCoreModelsProductionCalculatorSettingResponse,
    MelcoCoreModelsProductionCalculatorSettingResponseFromJSON,
    MelcoCoreModelsProductionCalculatorSettingResponseFromJSONTyped,
    MelcoCoreModelsProductionCalculatorSettingResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorCalculationDetail
 */
export interface MelcoCoreModelsProductionCalculatorCalculationDetail {
    /**
     * 
     * @type {MelcoCoreModelsProductionCalculatorSettingResponse}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationDetail
     */
    settings?: MelcoCoreModelsProductionCalculatorSettingResponse;
    /**
     * 
     * @type {MelcoCoreModelsProductionCalculatorMachineResponse}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationDetail
     */
    machine?: MelcoCoreModelsProductionCalculatorMachineResponse;
    /**
     * 
     * @type {MelcoCoreModelsProductionCalculatorJobResponse}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationDetail
     */
    job?: MelcoCoreModelsProductionCalculatorJobResponse;
    /**
     * 
     * @type {MelcoCoreModelsProductionCalculatorCalculation}
     * @memberof MelcoCoreModelsProductionCalculatorCalculationDetail
     */
    calculation?: MelcoCoreModelsProductionCalculatorCalculation;
}

export function MelcoCoreModelsProductionCalculatorCalculationDetailFromJSON(json: any): MelcoCoreModelsProductionCalculatorCalculationDetail {
    return MelcoCoreModelsProductionCalculatorCalculationDetailFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorCalculationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorCalculationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': !exists(json, 'settings') ? undefined : MelcoCoreModelsProductionCalculatorSettingResponseFromJSON(json['settings']),
        'machine': !exists(json, 'machine') ? undefined : MelcoCoreModelsProductionCalculatorMachineResponseFromJSON(json['machine']),
        'job': !exists(json, 'job') ? undefined : MelcoCoreModelsProductionCalculatorJobResponseFromJSON(json['job']),
        'calculation': !exists(json, 'calculation') ? undefined : MelcoCoreModelsProductionCalculatorCalculationFromJSON(json['calculation']),
    };
}

export function MelcoCoreModelsProductionCalculatorCalculationDetailToJSON(value?: MelcoCoreModelsProductionCalculatorCalculationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': MelcoCoreModelsProductionCalculatorSettingResponseToJSON(value.settings),
        'machine': MelcoCoreModelsProductionCalculatorMachineResponseToJSON(value.machine),
        'job': MelcoCoreModelsProductionCalculatorJobResponseToJSON(value.job),
        'calculation': MelcoCoreModelsProductionCalculatorCalculationToJSON(value.calculation),
    };
}


