/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorJobResponse
 */
export interface MelcoCoreModelsProductionCalculatorJobResponse {
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    Id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    orders?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    pieces?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    color_changes?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    cone_changes?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    garment_handling?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    hooping?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    other_work?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    last_modified?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorJobResponse
     */
    global_settings?: boolean;
}

export function MelcoCoreModelsProductionCalculatorJobResponseFromJSON(json: any): MelcoCoreModelsProductionCalculatorJobResponse {
    return MelcoCoreModelsProductionCalculatorJobResponseFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorJobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorJobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Id': !exists(json, 'Id') ? undefined : json['Id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orders': !exists(json, 'orders') ? undefined : json['orders'],
        'pieces': !exists(json, 'pieces') ? undefined : json['pieces'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'color_changes': !exists(json, 'color_changes') ? undefined : json['color_changes'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'cone_changes': !exists(json, 'cone_changes') ? undefined : json['cone_changes'],
        'garment_handling': !exists(json, 'garment_handling') ? undefined : json['garment_handling'],
        'hooping': !exists(json, 'hooping') ? undefined : json['hooping'],
        'other_work': !exists(json, 'other_work') ? undefined : json['other_work'],
        'last_modified': !exists(json, 'last_modified') ? undefined : (new Date(json['last_modified'])),
        'global_settings': !exists(json, 'global_settings') ? undefined : json['global_settings'],
    };
}

export function MelcoCoreModelsProductionCalculatorJobResponseToJSON(value?: MelcoCoreModelsProductionCalculatorJobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.Id,
        'name': value.name,
        'orders': value.orders,
        'pieces': value.pieces,
        'stitches': value.stitches,
        'colors': value.colors,
        'color_changes': value.color_changes,
        'trims': value.trims,
        'cone_changes': value.cone_changes,
        'garment_handling': value.garment_handling,
        'hooping': value.hooping,
        'other_work': value.other_work,
        'last_modified': value.last_modified === undefined ? undefined : (value.last_modified.toISOString()),
        'global_settings': value.global_settings,
    };
}


