/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsCreateCustomProduct,
    MelcoCoreModelsCreateCustomProductFromJSON,
    MelcoCoreModelsCreateCustomProductToJSON,
    MelcoCoreModelsCustomProduct,
    MelcoCoreModelsCustomProductFromJSON,
    MelcoCoreModelsCustomProductToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItem,
    MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItemToJSON,
    MelcoCoreModelsUpdateCustomProduct,
    MelcoCoreModelsUpdateCustomProductFromJSON,
    MelcoCoreModelsUpdateCustomProductToJSON,
} from '../models';

export interface FusionApiFusionCustomProductsCreateDraftRequest {
    melcoCoreModelsCreateCustomProduct?: MelcoCoreModelsCreateCustomProduct;
}

export interface FusionApiFusionCustomProductsDefaultPreviewRequest {
    id: string | null;
    width?: number;
    publishState?: string | null;
    colorName?: string | null;
    viewName?: string | null;
}

export interface FusionApiFusionCustomProductsDefaultTokenPreviewRequest {
    productToken: string | null;
    width?: number;
    publishState?: string | null;
    colorName?: string | null;
    viewName?: string | null;
}

export interface FusionApiFusionCustomProductsDeleteRequest {
    id: string | null;
    force?: boolean;
}

export interface FusionApiFusionCustomProductsDuplicateDraftRequest {
    id: string | null;
}

export interface FusionApiFusionCustomProductsFixSchemaRequest {
    melcoCoreModelsCustomProduct?: MelcoCoreModelsCustomProduct;
}

export interface FusionApiFusionCustomProductsGetByTokenRequest {
    token: string | null;
}

export interface FusionApiFusionCustomProductsGetDefRequest {
    id: string | null;
    publishState?: string | null;
}

export interface FusionApiFusionCustomProductsPreviewRequest {
    id: string | null;
    imageId: string | null;
    width?: number;
    publishState?: string | null;
}

export interface FusionApiFusionCustomProductsPublishRequest {
    id: string | null;
}

export interface FusionApiFusionCustomProductsRevertRequest {
    id: string | null;
    force?: boolean;
}

export interface FusionApiFusionCustomProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string | null;
    searchText?: string | null;
    publishState?: string | null;
}

export interface FusionApiFusionCustomProductsUpdateDraftRequest {
    id: string | null;
    melcoCoreModelsUpdateCustomProduct?: MelcoCoreModelsUpdateCustomProduct;
}

export interface FusionApiUserBlanksPreviewRequest {
    id: string | null;
    height?: number;
    width?: number;
    publishState?: string | null;
}

/**
 * 
 */
export class FusionApi extends runtime.BaseAPI {

    /**
     * creates custom product
     * create custom product
     */
    async fusionCustomProductsCreateDraftRaw(requestParameters: FusionApiFusionCustomProductsCreateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsCreateCustomProductToJSON(requestParameters.melcoCoreModelsCreateCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * creates custom product
     * create custom product
     */
    async fusionCustomProductsCreateDraft(requestParameters: FusionApiFusionCustomProductsCreateDraftRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get product default preview image
     */
    async fusionCustomProductsDefaultPreviewRaw(requestParameters: FusionApiFusionCustomProductsDefaultPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDefaultPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.colorName !== undefined) {
            queryParameters['color_name'] = requestParameters.colorName;
        }

        if (requestParameters.viewName !== undefined) {
            queryParameters['view_name'] = requestParameters.viewName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get product default preview image
     */
    async fusionCustomProductsDefaultPreview(requestParameters: FusionApiFusionCustomProductsDefaultPreviewRequest): Promise<void> {
        await this.fusionCustomProductsDefaultPreviewRaw(requestParameters);
    }

    /**
     * get image
     * get product default preview image by token
     */
    async fusionCustomProductsDefaultTokenPreviewRaw(requestParameters: FusionApiFusionCustomProductsDefaultTokenPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productToken === null || requestParameters.productToken === undefined) {
            throw new runtime.RequiredError('productToken','Required parameter requestParameters.productToken was null or undefined when calling fusionCustomProductsDefaultTokenPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.colorName !== undefined) {
            queryParameters['color_name'] = requestParameters.colorName;
        }

        if (requestParameters.viewName !== undefined) {
            queryParameters['view_name'] = requestParameters.viewName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{product_token}/token_preview`.replace(`{${"product_token"}}`, encodeURIComponent(String(requestParameters.productToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get product default preview image by token
     */
    async fusionCustomProductsDefaultTokenPreview(requestParameters: FusionApiFusionCustomProductsDefaultTokenPreviewRequest): Promise<void> {
        await this.fusionCustomProductsDefaultTokenPreviewRaw(requestParameters);
    }

    /**
     * delete custom product
     */
    async fusionCustomProductsDeleteRaw(requestParameters: FusionApiFusionCustomProductsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete custom product
     */
    async fusionCustomProductsDelete(requestParameters: FusionApiFusionCustomProductsDeleteRequest): Promise<void> {
        await this.fusionCustomProductsDeleteRaw(requestParameters);
    }

    /**
     * duplicate custom product
     * duplicate custom product
     */
    async fusionCustomProductsDuplicateDraftRaw(requestParameters: FusionApiFusionCustomProductsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * duplicate custom product
     * duplicate custom product
     */
    async fusionCustomProductsDuplicateDraft(requestParameters: FusionApiFusionCustomProductsDuplicateDraftRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * fix product schema
     * fix schema for views etc
     */
    async fusionCustomProductsFixSchemaRaw(requestParameters: FusionApiFusionCustomProductsFixSchemaRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/fixschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsCustomProductToJSON(requestParameters.melcoCoreModelsCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * fix product schema
     * fix schema for views etc
     */
    async fusionCustomProductsFixSchema(requestParameters: FusionApiFusionCustomProductsFixSchemaRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsFixSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * get custom product by token
     * get published custom product by token
     */
    async fusionCustomProductsGetByTokenRaw(requestParameters: FusionApiFusionCustomProductsGetByTokenRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling fusionCustomProductsGetByToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/token/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * get custom product by token
     * get published custom product by token
     */
    async fusionCustomProductsGetByToken(requestParameters: FusionApiFusionCustomProductsGetByTokenRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsGetByTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * get custom product
     * get custom product
     */
    async fusionCustomProductsGetDefRaw(requestParameters: FusionApiFusionCustomProductsGetDefRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * get custom product
     * get custom product
     */
    async fusionCustomProductsGetDef(requestParameters: FusionApiFusionCustomProductsGetDefRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get product preview image
     */
    async fusionCustomProductsPreviewRaw(requestParameters: FusionApiFusionCustomProductsPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsPreview.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling fusionCustomProductsPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/preview/{Image_Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"Image_Id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get product preview image
     */
    async fusionCustomProductsPreview(requestParameters: FusionApiFusionCustomProductsPreviewRequest): Promise<void> {
        await this.fusionCustomProductsPreviewRaw(requestParameters);
    }

    /**
     * publish custom product
     * publish custom product
     */
    async fusionCustomProductsPublishRaw(requestParameters: FusionApiFusionCustomProductsPublishRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * publish custom product
     * publish custom product
     */
    async fusionCustomProductsPublish(requestParameters: FusionApiFusionCustomProductsPublishRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async fusionCustomProductsRevertRaw(requestParameters: FusionApiFusionCustomProductsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async fusionCustomProductsRevert(requestParameters: FusionApiFusionCustomProductsRevertRequest): Promise<void> {
        await this.fusionCustomProductsRevertRaw(requestParameters);
    }

    /**
     * search user custom products
     */
    async fusionCustomProductsSearchRaw(requestParameters: FusionApiFusionCustomProductsSearchRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItemFromJSON(jsonValue));
    }

    /**
     * search user custom products
     */
    async fusionCustomProductsSearch(requestParameters: FusionApiFusionCustomProductsSearchRequest): Promise<MelcoCoreModelsGenericList1MelcoCoreModelsCustomProductListItem> {
        const response = await this.fusionCustomProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing custom product
     * update existing custom product
     */
    async fusionCustomProductsUpdateDraftRaw(requestParameters: FusionApiFusionCustomProductsUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoCoreModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsUpdateCustomProductToJSON(requestParameters.melcoCoreModelsUpdateCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsCustomProductFromJSON(jsonValue));
    }

    /**
     * update existing custom product
     * update existing custom product
     */
    async fusionCustomProductsUpdateDraft(requestParameters: FusionApiFusionCustomProductsUpdateDraftRequest): Promise<MelcoCoreModelsCustomProduct> {
        const response = await this.fusionCustomProductsUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get blank canvas preview image
     */
    async userBlanksPreviewRaw(requestParameters: FusionApiUserBlanksPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlanksPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.height !== undefined) {
            queryParameters['height'] = requestParameters.height;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/canvas/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get blank canvas preview image
     */
    async userBlanksPreview(requestParameters: FusionApiUserBlanksPreviewRequest): Promise<void> {
        await this.userBlanksPreviewRaw(requestParameters);
    }

}
