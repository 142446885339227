/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsSecurityApiKeyAuth,
    MelcoCoreModelsSecurityApiKeyAuthFromJSON,
    MelcoCoreModelsSecurityApiKeyAuthToJSON,
    MelcoCoreModelsSecurityAuthToken,
    MelcoCoreModelsSecurityAuthTokenFromJSON,
    MelcoCoreModelsSecurityAuthTokenToJSON,
    MelcoCoreModelsSecurityJwtAuth,
    MelcoCoreModelsSecurityJwtAuthFromJSON,
    MelcoCoreModelsSecurityJwtAuthToJSON,
    MelcoCoreModelsSecurityMelcoAuth,
    MelcoCoreModelsSecurityMelcoAuthFromJSON,
    MelcoCoreModelsSecurityMelcoAuthToJSON,
    MelcoCoreModelsSecuritySingleUseCodeAuth,
    MelcoCoreModelsSecuritySingleUseCodeAuthFromJSON,
    MelcoCoreModelsSecuritySingleUseCodeAuthToJSON,
    MelcoCoreModelsSecurityVirtualAuth,
    MelcoCoreModelsSecurityVirtualAuthFromJSON,
    MelcoCoreModelsSecurityVirtualAuthToJSON,
    MelcoCoreModelsSecurityVirtualLogin,
    MelcoCoreModelsSecurityVirtualLoginFromJSON,
    MelcoCoreModelsSecurityVirtualLoginToJSON,
    MelcoCoreModelsSupportToken,
    MelcoCoreModelsSupportTokenFromJSON,
    MelcoCoreModelsSupportTokenToJSON,
} from '../models';

export interface AuthenticationApiAuthenticationRequest {
    token?: string | null;
}

export interface AuthenticationApiAuthenticationAuthenticateJwtRequest {
    melcoCoreModelsSecurityJwtAuth?: MelcoCoreModelsSecurityJwtAuth;
}

export interface AuthenticationApiAuthenticationAuthenticateJwtTokenRequest {
    melcoCoreModelsSecurityJwtAuth?: MelcoCoreModelsSecurityJwtAuth;
}

export interface AuthenticationApiAuthenticationAuthenticateMelcoRequest {
    melcoCoreModelsSecurityMelcoAuth?: MelcoCoreModelsSecurityMelcoAuth;
}

export interface AuthenticationApiAuthenticationLoginByApiKeyRequest {
    melcoCoreModelsSecurityApiKeyAuth?: MelcoCoreModelsSecurityApiKeyAuth;
}

export interface AuthenticationApiAuthenticationLoginBySingleUseCodeRequest {
    melcoCoreModelsSecuritySingleUseCodeAuth?: MelcoCoreModelsSecuritySingleUseCodeAuth;
}

export interface AuthenticationApiAuthenticationVirtualLoginRequest {
    melcoCoreModelsSecurityVirtualAuth?: MelcoCoreModelsSecurityVirtualAuth;
}

export interface AuthenticationApiSupportValidateTokenRequest {
    melcoCoreModelsSupportToken?: MelcoCoreModelsSupportToken;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * validate and return auth token
     * verify token
     */
    async authenticationRaw(requestParameters: AuthenticationApiAuthenticationRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * validate and return auth token
     * verify token
     */
    async authentication(requestParameters: AuthenticationApiAuthenticationRequest): Promise<string> {
        const response = await this.authenticationRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/jwt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecurityJwtAuthToJSON(requestParameters.melcoCoreModelsSecurityJwtAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwt(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationAuthenticateJwtRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtTokenRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtTokenRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecurityJwtAuthToJSON(requestParameters.melcoCoreModelsSecurityJwtAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtToken(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtTokenRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationAuthenticateJwtTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using melco authentication system
     */
    async authenticationAuthenticateMelcoRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateMelcoRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/melco`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecurityMelcoAuthToJSON(requestParameters.melcoCoreModelsSecurityMelcoAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using melco authentication system
     */
    async authenticationAuthenticateMelco(requestParameters: AuthenticationApiAuthenticationAuthenticateMelcoRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationAuthenticateMelcoRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using api key
     */
    async authenticationLoginByApiKeyRaw(requestParameters: AuthenticationApiAuthenticationLoginByApiKeyRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/apikey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecurityApiKeyAuthToJSON(requestParameters.melcoCoreModelsSecurityApiKeyAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using api key
     */
    async authenticationLoginByApiKey(requestParameters: AuthenticationApiAuthenticationLoginByApiKeyRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationLoginByApiKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using single use code
     */
    async authenticationLoginBySingleUseCodeRaw(requestParameters: AuthenticationApiAuthenticationLoginBySingleUseCodeRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/singleusecode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecuritySingleUseCodeAuthToJSON(requestParameters.melcoCoreModelsSecuritySingleUseCodeAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using single use code
     */
    async authenticationLoginBySingleUseCode(requestParameters: AuthenticationApiAuthenticationLoginBySingleUseCodeRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationLoginBySingleUseCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffByApiKeyRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/apikey/logoff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffByApiKey(): Promise<void> {
        await this.authenticationLogoffByApiKeyRaw();
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffMelcoRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/melco/logoff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffMelco(): Promise<void> {
        await this.authenticationLogoffMelcoRaw();
    }

    /**
     * validate and return auth token
     * refresh melco authentication cookie (requires configuration from Melco)
     */
    async authenticationMelcoRefreshRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/melco/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * refresh melco authentication cookie (requires configuration from Melco)
     */
    async authenticationMelcoRefresh(): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.authenticationMelcoRefreshRaw();
        return await response.value();
    }

    /**
     * validate user and return product features
     * virtual login
     */
    async authenticationVirtualLoginRaw(requestParameters: AuthenticationApiAuthenticationVirtualLoginRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityVirtualLogin>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/melco/virtual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSecurityVirtualAuthToJSON(requestParameters.melcoCoreModelsSecurityVirtualAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityVirtualLoginFromJSON(jsonValue));
    }

    /**
     * validate user and return product features
     * virtual login
     */
    async authenticationVirtualLogin(requestParameters: AuthenticationApiAuthenticationVirtualLoginRequest): Promise<MelcoCoreModelsSecurityVirtualLogin> {
        const response = await this.authenticationVirtualLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate token and return auth token
     */
    async supportValidateTokenRaw(requestParameters: AuthenticationApiSupportValidateTokenRequest): Promise<runtime.ApiResponse<MelcoCoreModelsSecurityAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/support/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCoreModelsSupportTokenToJSON(requestParameters.melcoCoreModelsSupportToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsSecurityAuthTokenFromJSON(jsonValue));
    }

    /**
     * Validate token and return auth token
     */
    async supportValidateToken(requestParameters: AuthenticationApiSupportValidateTokenRequest): Promise<MelcoCoreModelsSecurityAuthToken> {
        const response = await this.supportValidateTokenRaw(requestParameters);
        return await response.value();
    }

}
