/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCurrency,
    MelcoCoreModelsCurrencyFromJSON,
    MelcoCoreModelsCurrencyFromJSONTyped,
    MelcoCoreModelsCurrencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsProductionCalculatorSettingRequest
 */
export interface MelcoCoreModelsProductionCalculatorSettingRequest {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    vacation?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    holidays?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    sick?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    training?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    working_hours_each_day?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    monthly_rent?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    overhead?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    gross_hourly_salary?: number;
    /**
     * 
     * @type {MelcoCoreModelsCurrency}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    currency?: MelcoCoreModelsCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCoreModelsProductionCalculatorSettingRequest
     */
    recalculate_all_jobs?: boolean;
}

export function MelcoCoreModelsProductionCalculatorSettingRequestFromJSON(json: any): MelcoCoreModelsProductionCalculatorSettingRequest {
    return MelcoCoreModelsProductionCalculatorSettingRequestFromJSONTyped(json, false);
}

export function MelcoCoreModelsProductionCalculatorSettingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsProductionCalculatorSettingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vacation': !exists(json, 'vacation') ? undefined : json['vacation'],
        'holidays': !exists(json, 'holidays') ? undefined : json['holidays'],
        'sick': !exists(json, 'sick') ? undefined : json['sick'],
        'training': !exists(json, 'training') ? undefined : json['training'],
        'working_hours_each_day': !exists(json, 'working_hours_each_day') ? undefined : json['working_hours_each_day'],
        'monthly_rent': !exists(json, 'monthly_rent') ? undefined : json['monthly_rent'],
        'overhead': !exists(json, 'overhead') ? undefined : json['overhead'],
        'gross_hourly_salary': !exists(json, 'gross_hourly_salary') ? undefined : json['gross_hourly_salary'],
        'currency': !exists(json, 'currency') ? undefined : MelcoCoreModelsCurrencyFromJSON(json['currency']),
        'recalculate_all_jobs': !exists(json, 'recalculate_all_jobs') ? undefined : json['recalculate_all_jobs'],
    };
}

export function MelcoCoreModelsProductionCalculatorSettingRequestToJSON(value?: MelcoCoreModelsProductionCalculatorSettingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vacation': value.vacation,
        'holidays': value.holidays,
        'sick': value.sick,
        'training': value.training,
        'working_hours_each_day': value.working_hours_each_day,
        'monthly_rent': value.monthly_rent,
        'overhead': value.overhead,
        'gross_hourly_salary': value.gross_hourly_salary,
        'currency': MelcoCoreModelsCurrencyToJSON(value.currency),
        'recalculate_all_jobs': value.recalculate_all_jobs,
    };
}


