/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoAnalyticsModelsManageUserWidgets,
    MelcoAnalyticsModelsManageUserWidgetsFromJSON,
    MelcoAnalyticsModelsManageUserWidgetsToJSON,
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItem,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItemToJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItem,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItemToJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItem,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItemToJSON,
} from '../models';

export interface AnalyticsApiAnalytcisManageUserWizardsRequest {
    melcoAnalyticsModelsManageUserWidgets?: MelcoAnalyticsModelsManageUserWidgets;
}

export interface AnalyticsApiAnalyticsGetActiveUsersRequest {
    productCode?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     * get user widgets list
     */
    async analytcisGetUserWizardsRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItemFromJSON(jsonValue));
    }

    /**
     * get user widgets list
     */
    async analytcisGetUserWizards(): Promise<MelcoCoreModelsGenericList1MelcoAnalyticsModelsUserWidgetListItem> {
        const response = await this.analytcisGetUserWizardsRaw();
        return await response.value();
    }

    /**
     * get all widgets
     */
    async analytcisGetWizardsRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItemFromJSON(jsonValue));
    }

    /**
     * get all widgets
     */
    async analytcisGetWizards(): Promise<MelcoCoreModelsGenericList1MelcoAnalyticsModelsWidgetListItem> {
        const response = await this.analytcisGetWizardsRaw();
        return await response.value();
    }

    /**
     * manage user widgets
     */
    async analytcisManageUserWizardsRaw(requestParameters: AnalyticsApiAnalytcisManageUserWizardsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/widgets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoAnalyticsModelsManageUserWidgetsToJSON(requestParameters.melcoAnalyticsModelsManageUserWidgets),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * manage user widgets
     */
    async analytcisManageUserWizards(requestParameters: AnalyticsApiAnalytcisManageUserWizardsRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.analytcisManageUserWizardsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get active users analytic
     */
    async analyticsGetActiveUsersRaw(requestParameters: AnalyticsApiAnalyticsGetActiveUsersRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCode !== undefined) {
            queryParameters['productCode'] = requestParameters.productCode;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analytics/users/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItemFromJSON(jsonValue));
    }

    /**
     * get active users analytic
     */
    async analyticsGetActiveUsers(requestParameters: AnalyticsApiAnalyticsGetActiveUsersRequest): Promise<MelcoCoreModelsGenericList1MelcoAnalyticsModelsActiveUserListItem> {
        const response = await this.analyticsGetActiveUsersRaw(requestParameters);
        return await response.value();
    }

}
